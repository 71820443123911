import { getPresignedUrl, uploadImageToS3 } from "../api";

const useUpload = () => {
  const uploadImageToS3Api = async (image) => {
    let res = await getPresignedUrl(image.name);
    if (res.status == 200) {
      const presignedUrl = res.data.url;
      const res2 = await uploadImageToS3(presignedUrl, image);
      if (res2.status == 200) {
        return res.data.key;
      }
    }
  };
  return { uploadImageToS3Api };
};

export default useUpload;
