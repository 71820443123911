import React from "react";
import { Button } from "antd";
import "./style.scss";
 
const DefaultButton = (props) => {
  return (
    <Button
      className={
        props.customClass ? `default-btn ${props.customClass}` : "default-btn"
      }
      type={props.type ? props.type : "default"}
      onClick={() => props.clickHandler()}
      shape={props.shape? props.shape : "default"}
      size={props.size ? props.size : "middle"}
      disabled={props.disabled}
    >
      <span>
        {props.iconSide && props.iconSide.toLowerCase() == "left" && (
          <img alt="left_icon" src={props.icon} className="btn-leftIcon" />
        )}
        {props.text}
        {props.iconSide && props.iconSide.toLowerCase() == "right" && (
          <img alt="right-icon" src={props.icon} className="btn-rigthIcon" />
        )}
      </span>
    </Button>
  );
};

export default DefaultButton;

 
