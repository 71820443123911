import { useContext } from "react";
import { loginByGmail } from "../api";
import { AuthContext } from "../context/authContext";


const useLogin = () => {
    const { dispatch } = useContext(AuthContext);
   
    const login = async (email, name, gmailObject) => {
        dispatch({ type: "LOGIN_START" });
        try {
            const res = await loginByGmail(email, name, gmailObject);
            localStorage.setItem("user", JSON.stringify(res.data));
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });

        } catch (err) {
            dispatch({ type: "LOGIN_FAILURE", payload: err });
        }
    };
    return { login };
}

export default useLogin;
