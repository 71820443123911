import React from "react";
import "./TryItYourSelf.scss";
import { Button } from "antd";

const TryItYourSelf = (props) => {
  return (
    <div className="tryIt-section spacing-btw-component">
      <div className="tryIt-container">
        <div className="heading">Try it Yourself</div>
        <div className="text">
          Maximise your presence on web to showcase your images and drive more
          sales
        </div>

        <Button
          className="get-started-btn"
          onClick={() => props.setIsModalVisible(true)}
        >
          Sign up now
        </Button>
      </div>
    </div>
  );
};

export default TryItYourSelf;
