import React from 'react'
import LoadingImage from "./../../assets/images/loader-icon.gif";

const HoldingModal = (props) => {
  return (
    <div>
      <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={LoadingImage} width="150" height="130" />
          <h2 style={{
            color:'#DE6B32'
          }}>Hold on!</h2>
          <h2>{props.text}</h2>
        </div>
    </div>
  )
}

export default HoldingModal
