import { Fragment, useEffect, useState } from 'react'
import style from './alignBlock.module.css';

const AllignmentBlock = (props) => {
    const [leftMargin, setLeftMargin] = useState('');
    const [rightMargin, setRightMargin] = useState('');
    const [topMargin, setTopMargin] = useState('');
    const [bottomMargin, setBottomMargin] = useState('');
    const [allignment, setAllignment] = useState([]);
    const [dataObject, setDataObject] = useState(null);
    useEffect(() => {
 
        setDataObject(props.data);
        console.log("allignmnet_start: ", props.data);
        props.data.margin.forEach(element => {
            updateMarginValue(element.name, element.value)
        });
        //setAllignment(props.data.alignment);
    }, [props.data])

    const updateMarginValue = (key, value) => {
     
        if (key.toLowerCase() == 'top') {
            setTopMargin(value ? value : '0');
        }
        else if (key.toLowerCase() == 'left') {
            setLeftMargin(value ? value : '0');
        }
        else if (key.toLowerCase() == 'bottom') {
            setBottomMargin(value ? value : '0');
        }
        else {
            setRightMargin(value ? value : '0');
        }

    }

    const marginOnchangeHandler = (ev, key) => {
         console.log("allig: ", ev, key)
        console.log(dataObject);
        let val = dataObject.margin.find(el => el.name.toLowerCase() == key);
        val.value = ev;
        val.isSelected = true;
        props.updateMethod(dataObject);
        updateMarginValue(key, ev);
    }

    const allignmentChangeHandler = (id) => {
       
        let align = dataObject.alignment.map(item=>{
            if(item._id===id){
                item.isSelected = true
            }
            else{
                item.isSelected = false
            }
            return item
        })
        dataObject.alignment = align;
        setAllignment(align)
        
        props.updateMethod(dataObject);
    }


    return (
        <Fragment>
            {
                dataObject &&

                <div className={style.alignParentBlock}>

                    <div className={style.alignParentSideChild}>
                        <div className={style.sideChildContent}>
                            <div className={style.atomBlock}>
                                <input
                                    id={props.id}
                                    type="number"
                                    onChange={(ev)=>marginOnchangeHandler(ev.target.value,'left')}
                                    value={leftMargin}
                                    className={style.marginClass}
                                ></input>
                            </div>
                            <div style={{marginTop:"10px"}} className={style.atomBlock}>
                                px
                            </div>

                        </div>
                    </div>
                    <div className={style.alignParentCenterChild}>
                        <div className={style.topMarginBlock}>
                            <div className={style.topMarginInputBlock}>
                                <input
                                    id={props.id}
                                    type="number"
                                    min="0"
                                    onChange={(ev)=>marginOnchangeHandler(ev.target.value,'top')}
                                    value={topMargin}
                                    // onChange={props.onChangeMethod}
                                    // value={props.value}
                                    className={style.marginClass}
                                ></input>
                                &nbsp;
                                px

                            </div>

                        </div>
                        <div className={style.alignmentSelection}>
                            {/* {
                                
                                allignment.map(item=>{
                                    return(
                                        <div key={item._id} onClick={(e)=>allignmentChangeHandler(item._id)}  className={item.isSelected?'alignSection_selected':'alignSection'}>{item.name}</div>
                                    )
                                })
                            } */}
                        </div>
                        <div className={style.topMarginBlock}>
                            <div className={style.topMarginInputBlock}>
                                <input
                                    id={props.id}
                                    type="number"
                                    min="0"
                                    onChange={(ev)=>marginOnchangeHandler(ev.target.value,'bottom')}
                                    value={bottomMargin}
                                    // onChange={props.onChangeMethod}
                                    // value={props.value}
                                    className={style.marginClass}
                                ></input>
                                &nbsp;
                                px

                            </div>

                        </div>
                    </div>
                    <div className={style.alignParentSideChild}>
                        <div className={style.sideChildContent}>
                            <div className={style.atomBlock}>
                                <input
                                    id={props.id}
                                    type="number"
                                    min="0"
                                    onChange={(ev)=>marginOnchangeHandler(ev.target.value,'right')}
                                    value={rightMargin}
                                    // onChange={props.onChangeMethod}
                                    // value={props.value}
                                    className={style.marginClass}
                                ></input>
                            </div>
                            <div style={{marginTop:"10px"}} className={style.atomBlock}>
                                px
                            </div>

                        </div>
                    </div>

                </div>
            }
        </Fragment>
    )
}

export default AllignmentBlock;
