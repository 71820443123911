import { createBatch, getBatch, downloadBatch } from "../api";
const useBatch = () => {
  const createBatchProcess = async (batchName, presets, files, email) => {
    const res = await createBatch(batchName, presets, files, email);
    if (res.status == 200) {
      return res.data;
    }
  };

  const getBatchRequests = async () => {
    const res = await getBatch();
    if (res.status == 200) {
      return res.data;
    }
  };

  return { createBatchProcess, getBatchRequests };
};

export default useBatch;

export const useDownloadFile = () => {
  const downloadBatchFile = async (fileUrl) => {
    const res = await downloadBatch(fileUrl);
    if (res.status == 200) {
      return res.data;
    }
  };
  return { downloadBatchFile };
};
