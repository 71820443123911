import { useState } from "react";
import { removebackgroundApi, getServiceStatus } from "../api";

const useRemoveBackground = () => {
  const [error, setError] = useState(null);
  const [successClip, setSuccessClip] = useState(false);

  const updateCustomState = (value) => {
    setSuccessClip(value);
  };

  const removeBackgroundImageFromApi = async (image) => {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("image", image);
      formData.append("adaptation_flag", "false");
      formData.append("hanger", "true");
      formData.append("keypoint", "true");
      formData.append("bbox", "true");
      formData.append("clip_cropped", "true");
      removebackgroundApi(formData)
        .then((res) => {
          console.log(res.data.serviceId, "response");
          // debugger;
          const serviceId = res.data.serviceId;
          let interval = setInterval(() => {
            getServiceStatus(serviceId)
              .then((res) => {
                 console.log(res);
                if (res.data.status == "completed") {
                  clearInterval(interval);
                  resolve(res.data);
                  console.log(res.data, "completed");
                  setSuccessClip(true);
                } else if (res.data.status == "pending") {
                  setSuccessClip(true);
                } else if (
                  res.data.status == "failed" ||
                  res.data.status == "rejected"
                ) {
                  clearInterval(interval);
                  reject(res);
                  setError(res);
                }
              })
              .catch((err) => {
                console.log(err);
                reject(err);
                setError(err);
                clearInterval(interval);
              });
          }, 2000);
        })
        .catch((err) => {
          console.log(err, "err");
          setError(err);
        });
    });
  };

  return {
    removeBackgroundImageFromApi,
    error,
    successClip,
    updateCustomState,
  };
};

export default useRemoveBackground;
