import { Steps } from "antd";
import React from "react";
import "./stepper.scss";
const { Step } = Steps;

const SideStepper = (props) => {
  return (
    <div className="stepper_main">
      <Steps
        direction="horizontal"
        current={props.currentStep}
        size="large"
        percent={60}
      >
        {props.stepArr.map((el, index) => (
          <Step
            key={index}
            title={el.name}
            className={
              index <= props.maxStep
                ? "cursor stepIcon"
                : "stepIcon disabledCursor"
            }
            onClick={() => props.maxStep >= index && props.setStep(el.key)}
          />
        ))}
      </Steps>
    </div>
  );
};

export default SideStepper;
