import React from "react";
import "./HeroBanner.scss";
import { Button } from "antd";
import heroBannerImg from "../../assets/images/hero-banner.svg";
import group_1 from "../../assets/images/group-1.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import TryNowBtn from "../../CommonComponents/TryNowBtn/TryNowBtn";

const HeroBanner = (props) => {
  return (
    <div className="heroBanner-section">
      <div className="heroBanner-container">
        <div className="left-container">
          <div className="heading">
            Resize your product
            <br />
            images for{" "}
            <span className="gt-family">
              multiple <br />
              marketplaces
            </span>{" "}
            &
            <br />
            social media platform{" "}
            <span className="platform-img">
              <img src={group_1} alt="" />
            </span>{" "}
            <br />
            in bulk
          </div>
          <div className="text">
            Tailored for garment images, our image resizer is specifically
            <br />
            designed to cater to the needs of brands, retailers, studios, and
            <br />
            long-tail clients.
          </div>
          <TryNowBtn onClick={props.scrollToComponent} />
        </div>
        <div className="right-container">
          <img src={heroBannerImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
