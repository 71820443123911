import { useState, useEffect } from "react";
import { Select } from "antd";
import { CloseOutlined, CloseSquareTwoTone } from "@ant-design/icons";
import "./style.scss";
import UploadImageIcon from "../../assets/images/uploadImageIcon.png";
import { Fragment } from "react";
const BackgroundImageBlock = ({backgroundImage, backgroundImageUrl,backGroundImageTypeChange,tileCountChange,resetBackgroundImage, changeBackgroundImage}) => {
    const [type, setType] = useState("cover");
    const [backGroundImageSrc, setBackGroundImageSrc] = useState(null);
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setType(value);
        backGroundImageTypeChange(value);
    }

    useEffect(() => {
        debugger;
        console.log("backgroundImage", backgroundImage);
        if (backgroundImageUrl) {
            setBackGroundImageSrc(backgroundImageUrl);
        }
        else if (backgroundImage){
            var fr = new FileReader();
            fr.onload = function () {
                setBackGroundImageSrc(fr.result);
            }
            fr.readAsDataURL(backgroundImage);
        }
    }, [backgroundImage, backgroundImageUrl]);

  return (
    <div className="backgroundImageBlock">
      <div className="configBlock">
          <Select
            defaultValue="cover"
            style={{ width: "70%", borderRadius: "none !important" }}
            className="dropdownElement"
            onChange={handleChange}
            options={[
              { value: "cover", label: "Cover" },
              { value: "contain", label: "Contain" },
              { value: "tile", label: "Tile" }
            ]}
          />
        <input type="number" min={1} disabled={type!="tile"} onChange={tileCountChange} style={{width:"20%", height:"30px", padding:"0.3rem"
    }}></input>
        
      </div>
      <div className="uploadSection">
            { backgroundImage ?
                <Fragment>
                    <img className="fitImage" src={backGroundImageSrc} alt="backgroundImage" />
                    <CloseSquareTwoTone onClick={resetBackgroundImage} className="closeButton" twoToneColor={'#D4D4D4'}/>
                </Fragment>
                :
                <label>
                    <input type="file"
                        onChange={changeBackgroundImage}
                     />
                    <img className="fitImage pointerCursor" src={UploadImageIcon} alt="uploadImageIcon"/>
                </label>

            }
        </div>
    </div>
  );
};

export default BackgroundImageBlock;
