import React, { useState } from "react";
import HeroBanner from "../HeroBanner/HeroBanner";
import OurNumberSpeak from "../OurNumberSpeak/OurNumberSpeak";
import TrustedCompanies from "../TrustedCompanies/TrustedCompanies";
import StepsConvertMultipleSizes from "../StepsConvertMultipleSizes/StepsConvertMultipleSizes";
import Features from "../Features/Features";
import UseCase from "../UseCase/UseCase";
import GalleryView from "../GalleryView/GalleryView";
import Testimonial from "../Testimonial/Testimonial";
import Faq from "../FAQS/Faq";
import LandingFooter from "../../CommonComponents/LandingFooter/LandingFooter";
import TryItYourSelf from "../TryItYourself/TryItYourSelf";
import AppTool from "../AppTool";
import DynamicModal from "../../CommonComponents/DynamicModal/DynamicModal";
import Login from "../Login/Login";

const LandingWrapper = (props) => {
  function scrollToComponent() {
    const rateUsReviewRef = document.getElementById("appTool");
    rateUsReviewRef.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <div>
      <HeroBanner scrollToComponent={scrollToComponent} />
      <OurNumberSpeak />
      <TrustedCompanies />
      <StepsConvertMultipleSizes />
      <div id="appTool">
        <AppTool authLogin={true} />
      </div>
      <Features />
      <UseCase setIsModalVisible={props.setIsModalVisible} />
      <GalleryView setIsModalVisible={props.setIsModalVisible} />
      <Testimonial />
      <Faq />
      <TryItYourSelf setIsModalVisible={props.setIsModalVisible} />
      <LandingFooter />
      <DynamicModal
        open={props.isModalVisible}
        closable={true}
        width={1200}
        close={() => {
          props.setIsModalVisible(false);
        }}
        customClass="login-modal"
        // footer={null}

        component={
          // <div style={{

          //   display:"flex",
          //   justifyContent:'center'
          // }}>
          //   <GoogleLogin
          //     onSuccess={(response) => {
          //       successLogin(jwt_decode(response.credential));
          //     }}
          //     onError={(response) => {
          //       console.log(response);
          //       setIsModalVisible(false);
          //     }}
          //   />
          // </div>
          <Login successLogin={props.googleLogin} />
        }
      />
      {/* <Featu */}
    </div>
  );
};

export default LandingWrapper;
