import axios from "axios";
const baseurl = "https://adaptationbackend.flixstock.com/imageedit";
const localurl = 'http://localhost:7071/imageedit';
const Instance2 = () => {
  return axios.create({
    accept: " application/json",
    baseURL: "https://adaptationbackend.flixstock.com/",
    //baseURL: 'http://localhost:7071/',
    timeout: 600000,
    headers: {
      "X-auth-token": `${localStorage.getItem("access_token")}`,
    },
  });
};

const loginByGmail = async (email, name, gmailObject) => {
  const res = await axios.post(`${baseurl}/users/login`, {
    email,
    name,
    gmailUser: gmailObject,
  });
  return res;
};
const getCredsGoogleLogin = (creds) => {
  const client = axios.create({
    baseURL: `https://www.googleapis.com/oauth2`,
    headers: {
      authorization: `Bearer ${creds.access_token}`,
      accept: "application/json",
    },
  });
  return client.request({
    method: "GET",
    url: `/v1/userinfo?access_token=${creds.access_token}`,
  });
};

const userGoogleLoginApi = async (data) => {
  const instance = Instance2();
  return instance.post("imageedit/users/login", data);
};
const guestLogin = async (email, name) => {
  const res = await axios.post(`${baseurl}/users/guestlogin`);
  return res;
};

const getMarketPlaces = async () => {
  const res = await axios.get(`${baseurl}/market-place`);
  return res;
};

const createPreset = async (preset) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.post(
    `${baseurl}/preset/create-preset`,
    { presetData: preset },
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    }
  );
  return res;
};

const getPreset = async () => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.get(`${baseurl}/preset`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
  return res;
};

const getPresignedUrl = async (imageName) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.get(
    `${baseurl}/adaptation/presigned?filename=${imageName}`,
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    }
  );
  return res;
};

const uploadImageToS3 = async (url, image) => {
  const res = axios.put(url, image);
  return res;
};

const createBatch = async (batchName, presets, files, email) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.post(
    `${baseurl}/adaptation/create`,
    {
      batchName,
      presets,
      email,
      files,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    }
  );
  return res;
};

const getBatch = async () => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.get(`${baseurl}/adaptation`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
  return res;
};

const updatePreset = async (preset, id) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.put(
    `${baseurl}/preset/update-preset/${id}`,
    { presetData: preset },
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    }
  );
  return res;
};

const downloadBatch = async (fileUrl) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const res = await axios.get(
    `${baseurl}/adaptation/signed?key=${fileUrl}`,
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
     }
  );
  return res;
};

const deletePresetById = async (id) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.delete(`${baseurl}/preset/delete-preset/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
  return res;
};

const removebackgroundApi = async (data) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.post(`${baseurl}/service/removeBackground`, data,{
    headers: {
      "x-auth-token": token
    }
  });
  return res;
};

const getServiceStatus = async (serviceId) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.get(`${baseurl}/service/${serviceId}`,{
    headers: {
      "x-auth-token": token
    }
  });
  return res;
}

const getUserCredits = async() =>{
  const token = JSON.parse(localStorage.getItem("user")).token;
  const res = await axios.get(`${baseurl}/users/getCredits`,{
    headers: {
      "x-auth-token": token
    }
  });
  return res;
  // const instance = Instance2();
  // return instance.get("/imageedit/users/getCredits");

}

export {
  loginByGmail,
  guestLogin,
  getMarketPlaces,
  createPreset,
  getPreset,
  getPresignedUrl,
  uploadImageToS3,
  createBatch,
  getBatch,
  updatePreset,
  downloadBatch,
  deletePresetById,
  removebackgroundApi,
  getServiceStatus,
  getCredsGoogleLogin,
  userGoogleLoginApi,
  getUserCredits
};
