import { Button } from "antd";
import googleIcon from "./../assets/images/google.svg";

export const GoogleBtn = (props) => (
  <Button
    onClick={() => props.clickHandler()}
    className="google_btn"
    type="primary"
    size="large"
  >
    <img src={googleIcon} alt="" />
    {props.text}
  </Button>
);
