import React, { useEffect, useState } from "react";
import useBatch, { useDownload, useDownloadFile } from "../../hooks/useBatch";
import "./style.scss";
import DefaultButton from "../../CommonComponents/Button";
import { MoreOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import DynamicModal from "../../CommonComponents/DynamicModal/DynamicModal";
import RemoveBackgroundModal from "../../CommonComponents/ModalContents/RemoveBackgroundModal";
import modalImg from "../../assets/images/modalImg.png";
import PresetModal from "../../CommonComponents/ModalContents/PresetModal";
import SuccessModalContent from "../../CommonComponents/ModalContents/SuccessModalContent";

const Dashboard = () => {
  const [batchListing, setBatchListing] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [downloadFlag, setDownloadFlag] = useState(false);

  const { getBatchRequests } = useBatch();
  const { downloadBatchFile } = useDownloadFile();

  useEffect(() => {
    getBatchRequests().then((res) => {
       setBatchListing(res.adaptation);
    });
  }, []);

  const downloadResultantFile = (fileUrl) => {
    downloadBatchFile(fileUrl).then((res) => {
      console.log(res, "downloadBatchFile");
      const link = document.createElement("a");
      link.href = res.url;
      link.setAttribute("download", `FileName.pdf`);
      document.body.appendChild(link);
      link.click();
       // api.success({
      //   message: "Success!",
      //   description: "Download successfully !",
      //   placement: "topRight",
      // });
      setDownloadFlag(true)
    });
  };

  const buttonType = (item)=>{
    switch(item.ServiceStatus){
      case "completed":
        return (<DefaultButton
          text={"Download"}
          customClass="download_btn"
          clickHandler={() => downloadResultantFile(item?.ServiceResult.resultant)}
        />)
      case "failed":
        return (<DefaultButton
          text={"Failed"}
          customClass="pending_btn"
          disabled={true}
        />)
      default:
        return (
          <DefaultButton
            customClass="pending_btn"
            disabled={true}
            text={"pending"}
          />
        )
    }
  }

  return (
    <div className="divDashboardRoot">
      {contextHolder}
      <div className="dashboard_header">
        <h1 className="header_title">
          Welcome {JSON.parse(localStorage.getItem("user")).name}!
        </h1>
        <span className="header_subtitle">
          Get ready to ignite your imagination and take your !!
        </span>
      </div>
      <div className="batch_listContainer">
        {batchListing?.map((item, index) => {
          
          return (
            <div key={index} className="list_card">
              <span className="dashboard_span">{` #${index + 1}`}</span>
              <span className="dashboard_span">{item.ServiceData.batchName}</span>
              <span className="dashboard_span">{item.ServiceData.files.length}</span>
              <span className="dashboard_span">
                {item?.createdAt && new Date(item?.createdAt).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
              </span>
              <div className="dashboard_span">
              {/* <DefaultButton
                  text={item.status === "completed" ? "Download" : "pending"}
                  disabled={item.status === "pending" ? true : false}
                  customClass={
                    item.status === "pending" ? "pending_btn" : "download_btn"
                  }
                /> */}
                {
                  buttonType(item)
                }
              </div>
            </div>
          );
        })}
      </div>
      {downloadFlag && (
        <DynamicModal
          open={downloadFlag}
          width={500}
          close={() => {
            setDownloadFlag(false);
          }}
          closable={true}
          footer={null}
          customClassName="modal2"
          component={
            <SuccessModalContent
              successMsg={"Your Image Download Successfully"}
              // hr={true}
              // batchProcessBtn={false}
              // dashboard={false}
            />
          }
        />
      )}
    </div>
  );
};

export default Dashboard;
