import { getMarketPlaces } from "../api";

const useMarketplace = () => {
    const getMarketPlace = async () => {
        const res = await getMarketPlaces();
        return res.data.marketPlace;
    };
    return { getMarketPlace };
}

export default useMarketplace;