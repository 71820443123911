import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./modal.scss";

const SuccessModalContent = (props) => {
  console.log(props, "batchSuccessContent");
  const navigate = useNavigate();

  const goToDashboard = () => {
    // if (props.finalSubmitBatch) {
    //   props.finalSubmitBatch();
    // }
    navigate("/dashboard");
  };

  const goToBatchProcess = () => {
    navigate("./batchProcess");
  };

  return (
    <div className="batch_success">
      <h1>{props.successMsg}</h1>
      {props.subHeading && <span>{props.subHeading}</span>}
      {props.hr && <hr />}
      <div className="success_content">
        {props.dashboard && (
          <Button className="dashboard_btn" onClick={goToDashboard}>
            Go To Dashboard
          </Button>
        )}
        {/* {props.batchProcessBtn && (
          <Button className="dashboard_btn" onClick={goToBatchProcess}>
            Go To Batch Process
          </Button>
        )} */}
        {props.confirm && (
          <Button className="dashboard_btn" onClick={props.confirmHandler}>
            Confirm
          </Button>
        )}
        {props.close && (
          <Button className="dashboard_btn" onClick={props.closeHandeler}>
            Close
          </Button>
        )}
      </div>
    </div>
  );
};

export default SuccessModalContent;
