import './style.scss';
import { Typography } from 'antd';
import RemoveBackgroundImage from '../../assets/images/Remove_Background_Img.png';

const ClipImage = ({clipEvent, clipEventShadow})=>{
    return(
    <div className="clipImage">
        <div className='containerBlock' onClick={clipEvent}>
            <img src={RemoveBackgroundImage} className='imageClass'></img>
            <Typography.Text className='imageTitle'>Remove Background </Typography.Text>
        </div>
        <div className='containerBlock' onClick={clipEventShadow}>
            <img src={RemoveBackgroundImage} className='imageClass'></img>
            <Typography.Text className='imageTitle'>Remove Background Keep Shadow</Typography.Text>
        </div>
    </div>
    )
}

export default ClipImage;