import { Button, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import DynamicModal from "../../CommonComponents/DynamicModal/DynamicModal";
import "./style.scss";
import SuccessModalContent from "../../CommonComponents/ModalContents/SuccessModalContent";
import { set } from "lodash";

const EmailCard = (props) => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isModalFlag, setIsModalFlag] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [batchName, setBatchName] = useState("");


  useEffect(()=>{
     
    const localStorageData = JSON.parse(localStorage.getItem('user'));
    if(localStorageData && localStorageData.userType=="client")
    {
      setClientEmail(localStorageData.email);
      setSelectedEmails([localStorageData.email]);
      props.updateSelectedEmails([localStorageData.email]);
    }

  },[])

  const onChangeHandler = (e) => {
    const { value } = e.target;
    const emailArray = value.split(",");
    setClientEmail(value);
    setSelectedEmails(emailArray);
    const removeSpace = selectedEmails.map((item) => item.trim());
    props.updateSelectedEmails(removeSpace);
  };

  const updateChange = (e) => {
    setBatchName(e.target.value);
    props.updateBatchName(e.target.value);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const finalSubmitBatch = () => {
     if(!batchName || !clientEmail)
    {
      notification.error({
        message: "Error!",
        description: "Please enter batch name and email",
        placement: "topRight",
      });
      return;
    }
    if(!validateEmail(clientEmail))
    {
      notification.error({
        message: "Error!",
        description: "Please enter valid email",
        placement: "topRight",
      });
      return;
    }
    props.updateSelectedEmails(selectedEmails);
    props.createBatch();
    setIsModalFlag(true);
  };

  return (
    <div className="center-Card">
      <div className="email_content">
        <Input
          type="email"
          className="custom_input"
          placeholder="Please enter your email"
          onChange={(e) => onChangeHandler(e)}
          value={clientEmail}
        />
        <Input
          className="custom_input"
          placeholder="Please enter your Batch name"
          onChange={(e) => updateChange(e)}
        />
      </div>
      <div
        style={{
          marginTop: "8rem",
        }}
      >
        <Button
          className="proceed_btn"
          style={{}}
          type="primary"
          onClick={() => {
            finalSubmitBatch();
          }}
        >
          Proceed
        </Button>
      </div>
      {isModalFlag && (
        <DynamicModal
          open={isModalFlag}
          closable={true}
          width={800}
          close={() => {
            setIsModalFlag(false);
          }}
          footer={null}
          component={
            <SuccessModalContent
              successMsg={"Batch Created Successfully"}
              // finalSubmitBatch={finalSubmitBatch}
              successToast={props?.successToast}
              dashboard={true}
              batchProcessBtn={true}
              subHeading={'You images will be ready in 24hrs, you’ll be notified via mail'}
            />
          }
        />
      )}
    </div>
  );
};

export default EmailCard;
