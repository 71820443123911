import React, {useContext, useEffect} from "react";
import { Layout } from "antd";
import AppTool from "../../component/AppTool";
import MainPoster from "../../component/TryMePoster";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../Header";
import BatchProcess from "../../component/BatchProcess";
import Dashboard from "../../component/Dashboard";
import {AuthContext} from "../../context/authContext";
const { Content } = Layout;

const AppLayout = () => {
  const location = useLocation();
  const {state} = useContext(AuthContext);
  
  useEffect(() => {
    // debugger;
    console.log("user", state);
  }, [state]);

  return (
    <Layout
      style={{
        background: "#FFFFFF",
        height: "92vh",
      }}
    >
      <Content>
        <Routes>
          <Route path="/" element={<AppTool />} />
          <Route path="/adaptationTool" element={<AppTool />} />
          <Route path="/batchProcess" element={<BatchProcess />} />
          {state && state.isAuth && <Route path="/dashboard" element={<Dashboard />} />}
        </Routes>
      </Content>
    </Layout>
  );
};

export default AppLayout;
