import { Typography } from "antd";
import {CloseSquareOutlined, CloseSquareFilled, CloseCircleFilled, CloseOutlined} from "@ant-design/icons";
import "./style.scss";
const PresetBlock = ({image,title,clickEvent, deleteEvent, closeOption})=>{
    return(
        <div className="presetBlock" onClick={clickEvent}>
            {closeOption &&
                <CloseOutlined style={{fontSize: "10px", color:"#000"}} onClick={deleteEvent} className="closeIcon" />
            }
            <img className="presetImage" src={image} alt="presetimage"/>
            <Typography.Title className="presetName" level={4}>{title}</Typography.Title>
        </div>
    )

}

export default PresetBlock;