import { useContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Modal } from "antd";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { AuthContext } from "./context/authContext";
import "./App.css";
import AppTool from "./component/AppTool";
import Header from "./CommonComponents/Header";
import MainPoster from "./component/TryMePoster";
import useLogin from "./hooks/useLogin";
import "./assets/scss/style.scss";
import AdaptationTools from "./component/BatchProcess";
import AppLayout from "./CommonComponents/Layout";
import ToasterProvider from "./context/toasterContext";
import LandingHeader from "./CommonComponents/LandingHeader/LandingHeader";
import LandingWrapper from "./component/LandingWrapper/LandingWrapper";
import DynamicModal from "./CommonComponents/DynamicModal/DynamicModal";
import Login from "./component/Login/Login";

import { getCredsGoogleLogin } from "./api";
function App() {
  const { state } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { login } = useLogin();

  const googleLogin = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (response) => {
      console.log(response, "credentialResponse");
      let responseByGmail = await getCredsGoogleLogin(response);
      console.log(responseByGmail, "reeeeeee");
      debugger;
      const data = await login(
        responseByGmail.data.email,
        responseByGmail.data.name,
        responseByGmail.data
      );
      setIsModalVisible(false);
    },
  });
  const successLogin = (response) => {
    console.log(response, "response");
    setTimeout(() => {
      login(response.email, response.name, response);
      setIsModalVisible(false);
    }, 1000);
  };

  console.log(state, "state");
  return (
    <div className="main">
      <BrowserRouter>
        {
          // Header Component <header auth={true/false}></header>
          //{state.isAuth && <Main Poster/>}
          <>
            {/* <Header login={() => setIsModalVisible(true)} auth={state.isAuth} /> */}
            {state.isAuth ? (
              <Header />
            ) : (
              <LandingHeader
                login={() => setIsModalVisible(true)}
                auth={state.isAuth}
              />
            )}

            {state && !state.isAuth && (
              <LandingWrapper
                googleLogin={googleLogin}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            )}
          </>
        }
        <ToasterProvider>{state.isAuth && <AppLayout />}</ToasterProvider>
      </BrowserRouter>
      <DynamicModal
        open={isModalVisible}
        closable={true}
        width={1200}
        close={() => {
          setIsModalVisible(false);
        }}
        customClass="login-modal"
        // footer={null}

        component={
          // <div style={{

          //   display:"flex",
          //   justifyContent:'center'
          // }}>
          //   <GoogleLogin
          //     onSuccess={(response) => {
          //       successLogin(jwt_decode(response.credential));
          //     }}
          //     onError={(response) => {
          //       console.log(response);
          //       setIsModalVisible(false);
          //     }}
          //   />
          // </div>
          <Login successLogin={googleLogin} />
        }
      />
    </div>
  );
}

export default App;
