import { createPreset, getPreset, updatePreset, deletePresetById  } from "../api";

const usePreset = () => {
    const savePreset = async (preset) => {
         const res = await createPreset(preset);
        return res.data;
    };

    const getYourPreset = async () => {
        const res = await getPreset();
        return res.data.presets;
    };
    const updateExistingPreset = async (preset, id) => {
        const res = await updatePreset(preset, id);
        return res.data;
    }
    const deletePreset = async (id) => {
        const res = await deletePresetById(id);
        return res.data;
    }

    return { savePreset, getYourPreset, updateExistingPreset, deletePreset };

    


}

export default usePreset;