import { guestLogin } from "../api"
const useGuestLogin = () => {
    const guestLoginProcess = async () => {
        const res = await guestLogin();
        if (res.status == 200) {
            return res.data;
        }
    };
    return { guestLoginProcess };
}

export default useGuestLogin;
