import React from "react";
import "./Testimonial.scss";
import AliceCarousel from "react-alice-carousel";
import avtarImg from "../../assets/images/avtar-img.png";
import double from "../../assets/images/double.svg";

import prev_btn from "../../assets/images/prev_btn.svg";
import next_btn from "../../assets/images/next_btn.svg";
import testimonalStrip from "../../assets/images/testimonal-strip.png";
const customerData = [
  {
    name: "Zoe Miller",
    avatar: avtarImg,
    location: "India | Chandigarh",
    feedback:
      "Working with a photo studio to obtain images of our apparels in the past has become a time-consuming task because of the many criteria’s and demands set from customers. As a middle-sized company, we found it difficult to find the time and resources to coordinate this process. Coordination with the studio to arrange the right model and ensuring studio executes as per brand’s styling requirement was extremely time consuming.",
  },
  // {
  //   name: "Dev",
  //   avatar: avatar,
  //   feedback:
  //     " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text  ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  // },
  // {
  //   name: "Shweta",
  //   avatar: avatar,
  //   feedback:
  //     " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text  ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  // },
  // {
  //   name: "Zoe Miller",
  //   avatar: avatar,
  //   feedback:
  //     " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text  ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  // },
];
const Testimonial = () => {
  return (
    <div className="testimonial-section spacing-btw-component">
      <div className="testimonial-container">
        <div className="testimonial-left-side">
          <span className="strip">
            {/* <img alt="" src={testimonalStrip} /> */}
          </span>
          {customerData?.map((elm, index) => {
            return (
              <div className="card_testimonial">
                <img
                  alt=""
                  src={double}
                  style={{
                    width: "30px",
                  }}
                  // breakPoints={breakPoints}
                />
                <div>
                  <p>{elm.feedback}</p>
                </div>
                <div className="card_header">
                  <img alt="" src={elm.avatar} />
                  <div>
                    <p>{elm.name}</p>
                    <p
                      style={{
                        fontSize: "12px ",
                        paddingTop: "5px",
                      }}
                    >
                      {elm.location}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="testimonial-right-side">
          <div className="sub-heading">Testimonial</div>
          <div className="heading">
            What Customer
            <br />
            Say About Us
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
