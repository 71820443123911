import { useState, useEffect, Fragment, useContext } from "react";
import { Button, Checkbox, Radio, Segmented } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import React from "react";
import nextArrow from "../../assets/images/for-svg.svg";
import backArrow from "../../assets/images/back-svg.svg";
import "./style.scss";
import usePreset from "../../hooks/usePreset";
import useMarketplace from "../../hooks/useMarketplace";
import yourPreImg from "../../assets/images/preset.png";
import { AuthContext } from "../../context/authContext";
import { PresetContext } from "../../context/guestPresets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CheckCircleFilled } from "@ant-design/icons";
import presetCard from "../../assets/images/preset-card.svg";
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={nextArrow} alt="next" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={backArrow} alt="next" />
    </div>
  );
}
const SelectPresets = (props) => {
  const [marketplace, setMarketplace] = useState([]);
  const [yourPreset, setYourPreset] = useState([]);
  const [selectPresetData, setSelectPresetData] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [selectedCard, setSelectedCard] = useState([]);
  const [mode, setMode] = useState("Marketplace");
  const { state } = useContext(AuthContext);
  const { GuestPresets } = useContext(PresetContext);
  const { getMarketPlace } = useMarketplace();
  const { getYourPreset } = usePreset();

  useEffect(() => {
    getMarketPlace().then((res) => {
      setMarketplace(res);
      setSelectPresetData(res);
    });
    if (state && state.isAuth) {
      getYourPreset().then((res) => {
        setYourPreset(res);
      });
    } else {
      GuestPresets && setYourPreset(GuestPresets);
    }
  }, []);

  useEffect(() => {
    if (mode === "Marketplace") {
      setSelectPresetData(marketplace);
    } else {
      setSelectPresetData(yourPreset);
    }
  }, [mode]);

  const changeSelectedPreset = (id, preset) => {
    if (selectedCards.includes(id)) {
      setSelectedCards(selectedCards.filter((cardId) => cardId !== id));
      props.setSelectedPreset(
        props.selectedPreset.filter((item) => item._id !== preset._id)
      );
    } else {
      setSelectedCards([...selectedCards, id]);
      props.setSelectedPreset([...props.selectedPreset, preset]);
    }
    // if (selectedCard.includes(id)) {
    //   setSelectedCard(selectedCard.filter((id) => id !== id));
    //   props.updateSelectedPreset(
    //     props.selectedPreset.filter((item) => item._id !== preset._id)
    //   );
    // } else {
    //   setSelectedCard(id);
    //   props.updateSelectedPreset([...props.selectedPreset, preset]);
    // }
  };

  console.log(props.selectedPreset, "selectedCard");
  const proceedNext = () => {
    if (selectedCards.length === 0) {
      alert("Please select atleast one preset");
      return;
    } else {
      props.updateSelectedPreset(props.selectedPreset);
      props.setCurrentStep(2);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // console.log(mode, "modemode");
  return (
    <div className="center-Card">
      <div className="preset_legend">
        <div className="preset_tab">
          <Segmented
            options={["Marketplace", "Your Preset"]}
            value={mode}
            onChange={setMode}
          />
        </div>
        <div className="preset_img">
          {mode === "Your Preset" ? (
            <Slider {...settings} key={Math.random()}>
              {selectPresetData &&
                selectPresetData.map((item, index) => {
                  return (
                    <div
                      className="preset_container"
                      onClick={(e) => changeSelectedPreset(item._id, item)}
                    >
                      <div
                        style={{
                          textAlign: "end",
                          padding: "10px",
                        }}
                      >
                        <CheckCircleFilled
                          style={{
                            color: selectedCards.includes(item._id)
                              ? "green"
                              : "grey",
                              
                              fontSize:'1.2rem'
                            }}
                        />
                      </div>
                      <div className="preset_box">
                        <img
                          src={presetCard}
                          alt=""
                          style={{
                            padding: "10px",
                          }}
                        />
                        <div className="text-center">{item.name}</div>
                      </div>
                      <div className="preset_data">
                        <span>
                          {item?.resolution?.height} &times;{" "}
                          {item?.resolution?.width}
                        </span>
                        <div className="inter">BG: {item.background}</div>
                        <span className="inter">
                          Margin: {item?.margin?.top}| {item?.margin?.left}|{" "}
                          {item?.margin?.bottom}|{item?.margin?.right}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          ) : (
            <Slider {...settings} key={Math.random()}>
              {selectPresetData &&
                selectPresetData.map((item, index) => {
                  return (
                    <div
                      key={item._id}
                      className={
                        selectedCard === item._id
                          ? "image_cont"
                          : "image_unSeleted"
                      }
                      onClick={(e) => changeSelectedPreset(item._id, item)}
                    >
                      <div className="text-center">
                        <div
                          style={{
                            textAlign: "end",
                            padding: "10px",
                          }}
                        >
                          <CheckCircleFilled
                            style={{
                              color: selectedCards.includes(item._id)
                                ? "green"
                                : "grey",
                                fontSize:'1.2rem'

                            }}
                          />
                        </div>
                        <img
                          alt="preset"
                          style={{
                            width: "20vw",
                            height: "25vh",
                            objectFit: "contain",
                            borderRadius: "8px",
                            padding: "1rem",
                          }}
                          src={item.imageUrl ? item.imageUrl : yourPreImg}
                        />
                        <div>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          )}
        </div>
      </div>
      <div
        style={
          {
            // marginTop: "1rem",
          }
        }
      >
        <Button className="proceed_btn" type="primary" onClick={proceedNext}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default SelectPresets;
