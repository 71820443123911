import { Button, Input } from "antd";
import React, { useState } from "react";
import "./style.scss";

const AdaptionForm = (props) => {
 
  const updateChange = (e) => {
     props.updateBatchName(e.target.value);
  };

   return (
    <div className="form_batch">
      <div className="input_batch form-spacing ">
        <label>Name your Adaptation Batch:</label>
        <Input
          placeholder="Ex: Pilot Pack - Brand Name:2022"
          name={"name"}
          isDisable={false}
          allowClear
          value={props.batchName}
          className="name_input"
          onChange={(e) => updateChange(e)}
        />
      </div>
      <Button
        className="proceed_btn"
        type="primary"
        onClick={() => {
          props.setCurrentStep(1);
        }}
        disabled={props.batchName.length === 0 && true}
      >
        Proceed
      </Button>
    </div>
  );
};

export default AdaptionForm;
