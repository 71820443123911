import React from "react";
import DefaultButton from "../Button";
import "./modal.scss";

const RemoveBackgroundModal = (props) => {
  console.log(props,"remove background modal")
  return (
    <div className="remove_container">
      <span className="span_text">Remove Background</span>
      <div className="image_wrapper">
        {/* {props.imageModalUrl */}
        <img style={{
          Width:"100%",
          height:"100%",
          objectFit: "contain",
        }} src={props.imageModalUrl} alt="modal_img" />
      </div>
      <div className="remove_footer">
        <DefaultButton customClass="remove_btn"  text={"Remove Background"} clickHandler={props.removeBackground} />
        <DefaultButton customClass="keep_btn" text={"Keep Original"}  clickHandler={props.keepOriginal}/>
      </div>
    </div>
  );
};

export default RemoveBackgroundModal;
