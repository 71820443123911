import React, { useEffect } from "react";
import { Modal } from "antd";
import "./style.scss";
const DynamicModal = (props) => {
  useEffect(() => {
    console.log("props", props);
  }, []);
  
  return (

       <Modal
        open={props.open}
        // closable={props.closable ? props.closable : false}
        closable={props.closable?true:false}
        onCancel={props.close}
        footer={null}
        width={props.width}
        title={props.title}
        centered
        className={props.customClassName ?? ""}
        bodyStyle={{
          // height:"550px"
        }}
        
        // maskClosable={true}
      >
        {props.component && props.component}
      </Modal>
   );
};

export default DynamicModal;
