import {getUserCredits } from "../api";

const useGetCredits = () => {
  const getUserAvailableCredits = async () => {
    const res = await getUserCredits();
    return res.data;
  };
  return { getUserAvailableCredits };

};

export default useGetCredits;
