import { createContext, useReducer } from "react";

const PresetContext = createContext();
const initialState = [];

const presetReducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRESET":
      return [...state, action.payload];
    case "REMOVE_PRESET":
      return state.filter((preset) => preset.name !== action.payload);
    case "UPDATE_PRESET":
      return state.map((preset) => {
        if (preset.name === action.payload.name) {
          return action.payload;
        }
        return preset;
      });
  }
};

const PresetContextProvider = ({ children }) => {
  const [state, presetDispatch] = useReducer(presetReducer, initialState);
  return (
    <PresetContext.Provider value={{ GuestPresets: state, presetDispatch }}>
      {children}
    </PresetContext.Provider>
  );
};
export { PresetContext, PresetContextProvider };
