import React from "react";
import { Button, Layout } from "antd";
// import "./TopBar.scss";
// import logo from "../../assets/images/ImageResizer.svg";
import "./style.scss";
const { Header } = Layout;

const LandingHeader = (props) => {
  return (
    <div className="header-section">
      <div className="header-container">
        <div className="logo">
          {/* <img src={logo} alt='' /> */}
          Image<span>Resizer</span>
        </div>

        <div className="login-section">
          <Button className="btn signIn-btn" onClick={props.login}>
            Sign in
          </Button>
          <Button className="btn signUp-btn" onClick={props.login}>
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
