import { Button, Modal, Progress, notification } from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import dropIcon from "../../assets/images/upload_icon.png";
import cancelIcon from "../../assets/images/cancel.png";
import useUpload from "../../hooks/useUpload";
import { FileDrop } from "react-file-drop";

const UploadImage = (props) => {
  const { uploadImageToS3Api } = useUpload();
  const [progress, setProgress] = useState(0);
  const [progressModal, setProgressModal] = useState(false);
  const [imgPreview, setImgPreview] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const handleFileUpload = async (e) => {
    console.log(e.target.files);
    let images = [];
    const files = e.target.files;
    console.log(files, "filesqqq");
    const fileArray = Array.from(files);
    props.updateProgressLimit(fileArray.length);
    let count = 0;
    setProgressModal(true);
    for (let i = 0; i < fileArray.length; i++) {
      const file = fileArray[i];
      console.log(file.name);
      const res = await uploadImageToS3Api(file);
      console.log(res);
      const key = res;
      props.updateSelectedImage((prev) => [...prev, { key, name: file.name }]);
      count++;
      setProgress(count);
      images.push(URL.createObjectURL(file));
      props.updateImagePreview([...images, ...props.imagesPreview]);
      setProgressModal(true);
    }
    setProgressModal(false);
  };

  const handleDropUpload = async (e) => {
    // alert("heello")
    let images = [];
    const files = e;

    const fileArray = Array.from(files);
    props.updateProgressLimit(fileArray.length);
    let count = 0;
    setProgressModal(true);
    for (let i = 0; i < fileArray.length; i++) {
      const file = fileArray[i];
      console.log(file.name);
      const res = await uploadImageToS3Api(file);
      console.log(res);
      const key = res;
      props.updateSelectedImage((prev) => [...prev, { key, name: file.name }]);
      count++;
      setProgress(count);
      images.push(URL.createObjectURL(file));
      props.updateImagePreview([...images, ...props.imagesPreview]);
      setProgressModal(true);
    }
    setProgressModal(false);
  };
  const proceedNext = () => {
    if (props.selectedImage.length === 0) {
      alert("Please select atleast one image");
      return;
    } else {
      props.updateSelectedImage(props?.selectedImage);
      props.setCurrentStep(1);
    }
  };

  const updateImagePreview = props.imagesPreview.map((item, index) => {
    return { url: item, id: index + 1 };
  });

  useEffect(() => {
    if (props.selectedImage.length !== 0) {
      setImgPreview(updateImagePreview);
    }
  }, [props.imagesPreview]);

  const deleteImage = (imgId) => {
    const finalUpdate = imgPreview.filter((item) => item.id !== imgId);
    setImgPreview(finalUpdate);
    api.success({
      message: "Success!",
      description: "Image delete successfully !",
      placement: "topRight",
    });
  };

  return (
    <>
      {contextHolder}
      {props.imagesPreview.length !== 0 && (
        <div className="total_files">
          <span>{`${imgPreview.length} images`}</span>
        </div>
      )}

      <div className="center-Card">
        <div
          style={{
            display: "flex",
            gap: "60px",
          }}
        >
          <FileDrop className="full_width" onDrop={handleDropUpload}>
            <div className="upload_container">
              <div className="drag_dropCard">
                <div className="drag_content">
                  <div>
                    <img alt="" src={dropIcon} />
                  </div>
                  <div></div>
                  <label class="custom-file-upload">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <p
                      style={{
                        margin: "10px",
                        color: "#DE6B32",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                       Drag and Drop your files Here
                      </span>
                    </p>
                  </label>
                  <span className="text_span">
                    jpg, jpeg, png & tiff files supported
                  </span>
                  <span className="text_span">
                    File size not more than 15MB
                  </span>
                </div>
              </div>
            </div>
          </FileDrop>
          <div className="show_uploadImage">
            {props.progressLimit === 0 ? (
              <span></span>
            ) : (
              <div
                style={{
                  height: "295px",
                  overflowY: "scroll",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {imgPreview?.map((item, index) => {
                  console.log(item, "itemmmm");
                  return (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={cancelIcon}
                        alt="icon"
                        style={{
                          position: "absolute",
                          left: "78%",
                          cursor: "pointer",
                          height: "12px",
                        }}
                        onClick={() => deleteImage(item.id)}
                      />
                      <img
                        key={index}
                        src={item.url}
                        alt=""
                        style={{
                          height: "118px",
                          width: "106px",
                          objectFit: "contain",
                          marginRight: "1rem",
                          // padding: "5px",
                          // border: "1px solid rgba(51, 51, 51, 0.85)",
                          // marginBottom: "1rem",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {progressModal && (
              <Modal
                open={progressModal}
                closable={false}
                footer={null}
                centered
              >
                <Progress
                  percent={parseInt((progress / props.progressLimit) * 100)}
                />
              </Modal>
            )}
          </div>
        </div>
        <div
          style={
            {
              // paddingLeft:"13rem"
            }
          }
        >
          <Button
            className="proceed_btn"
            style={
              {
                // marginTop: "3.5rem",
              }
            }
            type="primary"
            onClick={() => proceedNext()}
          >
            NEXT
          </Button>
        </div>
      </div>
    </>
  );
};

export default UploadImage;
