import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DownloadImage from "../DownloadImage/DownloadImage";
import EmailCard from "../EmailCard";
import AdaptionForm from "../Form/AdaptionForm";
import SelectPresets from "../SelectPresets/SelectPresets";
import SideStepper from "../Stepper/Stepper";
import UploadImage from "../UploadImage/UploadImage";
import useBatch from "../../hooks/useBatch";
import { AuthContext } from "../../context/authContext";
import "./style.scss";
import { LeftOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import DynamicModal from "../../CommonComponents/DynamicModal/DynamicModal";
import PresetModal from "../../CommonComponents/ModalContents/PresetModal";
import SuccessModalContent from "../../CommonComponents/ModalContents/SuccessModalContent";
const Context = React.createContext({
  name: "Default",
});
const BatchProcess = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [batchName, setBatchName] = useState("");
  const [selectedPreset, setSelectedPreset] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [progressLimit, setProgressBarLimit] = useState(0);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [presetFlag, setPresetFlag] = useState(false);
  const [presetSuccessFlag, setPresetSuccessFlag] = useState(false);

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const { createBatchProcess } = useBatch();
  const { user } = useContext(AuthContext);

  const [stepArr, setStepArr] = useState([
    {
      // name: "Name",
      key: 0,
      heading: "Upload Images",
      subHeading: "Get ready to ignite your imagination and take your !!",
    },
    {
      // name: "Choose Presets",
      key: 1,
      heading: "Where are you planning to use this images?",
      subHeading:
        "Get ready to ignite your imagination and take your projects to the next level with these incredible images!",
    },

    {
      // name: "Upload Images",
      key: 2,
      heading: "Please enter Batch name & email id.",
      subHeading:
        "Get ready to ignite your imagination and take your projects to the next level with these incredible images!",
    },
    // {
    //   // name: "Email",
    //   key: 3,
    //   heading: "Resultant File",
    //   subHeading: "Specify emails to receive final file ",
    // },
  ]);

  const updateBatchName = (name) => {
    setBatchName(name);
  };
  const updateSelectedPreset = (preset) => {
    setSelectedPreset(preset);
  };
  const updateSelectedImage = (image) => {
    setSelectedImage(image);
  };

  const updateSelectedEmails = (emails) => {
    setSelectedEmails(emails);
  };

  const updateProgressLimit = (progress) => {
    setProgressBarLimit(progress);
  };

  const updateImagePreview = (preview) => {
    setImagesPreview(preview);
  };

  const onNextProceed = () => {
    setCurrentStep(currentStep + 1);
  };

  const createBatch = () => {
    // debugger;
    console.log("batchName", batchName);
    console.log("selectedPreset", selectedPreset);
    console.log("selectedImage", selectedImage);
    createBatchProcess(batchName, selectedPreset, selectedImage, selectedEmails)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const componentRender = () => {
    switch (currentStep) {
      case 0:
        return (
          <UploadImage
            updateSelectedImage={updateSelectedImage}
            setCurrentStep={setCurrentStep}
            onNextProceed={onNextProceed}
            selectedImage={selectedImage}
            updateProgressLimit={updateProgressLimit}
            progressLimit={progressLimit}
            updateImagePreview={updateImagePreview}
            imagesPreview={imagesPreview}
          />
          // <AdaptionForm
          //   updateBatchName={updateBatchName}
          //   onNextProceed={onNextProceed}
          //   setCurrentStep={setCurrentStep}
          //   batchName={batchName}
          // />
        );
      case 1:
        return (
          <SelectPresets
            updateSelectedPreset={updateSelectedPreset}
            setCurrentStep={setCurrentStep}
            onNextProceed={onNextProceed}
            selectedPreset={selectedPreset}
            setSelectedPreset={setSelectedPreset}
          />
        );
      case 2:
        return (
          // <UploadImage
          //   updateSelectedImage={updateSelectedImage}
          //   setCurrentStep={setCurrentStep}
          //   onNextProceed={onNextProceed}
          //   selectedImage={selectedImage}
          //   updateProgressLimit={updateProgressLimit}
          //   progressLimit={progressLimit}
          //   updateImagePreview={updateImagePreview}
          //   imagesPreview={imagesPreview}
          // />
          <EmailCard
            setCurrentStep={setCurrentStep}
            onNextProceed={onNextProceed}
            updateSelectedEmails={updateSelectedEmails}
            createBatch={createBatch}
            updateBatchName={updateBatchName}
          />
        );
      case 3:
        return (
          <EmailCard
            setCurrentStep={setCurrentStep}
            onNextProceed={onNextProceed}
            updateSelectedEmails={updateSelectedEmails}
            createBatch={createBatch}
          />
        );
      default:
        return (
          <AdaptionForm
            setCurrentStep={setCurrentStep}
            onNextProceed={onNextProceed}
          />
        );
    }
  };
 
  return (
    <div className="batchRootPage">
 
      {contextHolder}
      <div className="batch_header">
        <div className="batch_content">
          {currentStep >= 1 && (
            <LeftOutlined
              style={{
                fontSize: "20px",
                marginRight: "10px",
              }}
              onClick={() => setCurrentStep(currentStep - 1)}
            />
          )}
          <span
            style={{
              fontSize: "20px",
              marginRight: "10px",
            }}
          >
            Step {currentStep + 1}
          </span>
          <div className="header_main">
            <span className="banner-heading-main">
              {stepArr[currentStep].heading}
            </span>
            <span className="header-subHeading">
              {stepArr[currentStep].subHeading}
            </span>
          </div>
        </div>

        <div className="batch_stepper">{/* <span>45 Images</span> */}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "-7rem",
          paddingRight: "60px",
        }}
      >
        <SideStepper
          setStep={(val) => setCurrentStep(val)}
          maxStep={currentStep}
          stepArr={stepArr}
          currentStep={currentStep}
        />
      </div>

      <div className="batch-form">
        <div className="step-container"></div>
        <div className="batch_process">
          <div className="component-render">{componentRender()}</div>
        </div>
      </div>
      
    </div>
  );
};

export default BatchProcess;
