import React from "react";
import "./StepsConvertMultipleSizes.scss";
import group_5 from "../../assets/images/Group-5.svg";
import group_2 from "../../assets/images/masking-1.svg";
import group_3 from "../../assets/images/masking-2.svg";
import group_4 from "../../assets/images/masking-3.svg";

const data = [
  {
    key: 1,
    img: group_2,
    heading: "Upload Image",
    text: "You can upload single image as well as multiple images",
  },
  {
    key: 2,
    img: group_3,
    heading: "Choose/Create Presets",
    text: "You can choose from marketplace or social media presets. you can also create your own presets",
  },
  {
    key: 3,
    img: group_4,
    heading: "Download Images",
    text: "Individual image as well as batch download available ",
  },
];
const StepsConvertMultipleSizes = () => {
  return (
    <div className="convert-section spacing-btw-component ">
      <div className="convert-container">
        <>
          <div className="heading gt-family">
            3 Step process to convert one image to <span className="gt-family">multiple sizes</span>
          </div>
          <div className="text_container">
            <span className="heading-img">
              <img src={group_5} alt="" style={{
                height:'90px',
                marginTop:"1.5rem"
              }} />
            </span>
            <div className="heading-text">
              Effortlessly convert a single image into multiple sizes and
              formats, streamlining your workflow with our <br />
              intuitive tool that takes just three simple steps to generate
              outputs for your desired marketplace.
            </div>
          </div>
        </>
        <div className="converting-steps">
          {/* {
            data.map((item,index) => (
              <div className='item' key={index}>
                <div className='item-img'>
                  <img src={item.img} alt='' />
                </div>
                <div className='item-heading'>{item.heading}</div>
                <div className='item-text'>{item.text}</div>
              </div>

            ))
           } */}
          <div className="item">
            <div className="item-img">
              <img src={group_2} alt=""  />
            </div>
            <div className="item-heading">Upload Image</div>
            <div className="item-text">
              You can upload single image <br />
              as well as multiple images
            </div>
          </div>
          <div className="item">
            <div className="item-img">
              <img src={group_3} alt="" />
            </div>
            <div className="item-heading">Choose/Create Presets</div>
            <div className="item-text">
              You can choose from marketplace <br />
              or social media presets. you can <br />
              also create your own presets
            </div>
          </div>
          <div className="item">
            <div className="item-img">
              <img src={group_4} alt="" />
            </div>
            <div className="item-heading">Download Images</div>
            <div className="item-text">
              Individual image as well as <br />
              batch download available{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsConvertMultipleSizes;
