import axios from "axios";

const getKeypoint=async (imageKey)=>{
    const res=await axios.post("https://imageedit.flixstock.com/cropper/keypoints",{
        "imagePath":imageKey,
        "imageBucket": "fxgati"
    })
    return res.data;
}

const UseKeypoint=()=>{
    const getKeypointApi=(imageKey)=>{
        return getKeypoint(imageKey);
    }
    return {getKeypointApi}
}

export default UseKeypoint;