import React, { useEffect, useState, useContext } from "react";
import "./style.scss";
import headerLogo from "../../../src/assets/images/adaptationLogo.png";
import DefaultButton from "../Button";
import { Avatar, Button, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import useGuestLogin from "../../hooks/useGuestLogin";
import useGetCredits from "../../hooks/getCredit";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("adaptationTool");
  const [availableCredits, setAvailableCredits] = useState("");
  const { dispatch } = useContext(AuthContext);
  const { guestLoginProcess } = useGuestLogin();
  const { getUserAvailableCredits } = useGetCredits();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    getUserAvailableCredits().then((res) => {
      console.log(res, "getUserAvailableCredits");
      setAvailableCredits(res.data.credits);
    });
  }, []);

  const logout = () => {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    guestLoginProcess().then((guestUser) => {
      localStorage.setItem("user", JSON.stringify(guestUser));
      navigate("/");
    });
  };
  const itemHandler = ({ key }) => {
    if (key == 1) {
      logout();
    }
  };
  let userDetails = JSON.parse(localStorage.getItem("user"));
  // console.log(data.name,"loca")

  const menu = (
    <Menu
      className="profileMenu"
      onClick={(e) => itemHandler(e)}
      items={[
        // {
        //   label: <span>Home</span>,
        //   key: 1,
        // },
        {
          label: <span>Credits : {availableCredits}</span>,
          key: 0,
        },
        {
          label: <span>Logout</span>,
          key: 1,
        },
      ]}
    />
  );
  return (
    <header>
      <div className="header_logoContent">
        <div className="header_logo">
          <img src={headerLogo} alt="logo" />
        </div>
        <div className="header_tabContent">
          {!props.auth && (
            <div className="content_withoutLogIn">
              {/* <span>Home</span> */}
            </div>
          )}
        </div>

        <div className="content_afterLogIn">
          <div className="tabContent">
            <div className="linkBtn" onClick={() => navigate("/dashboard")}>
              <span
                className={
                  activeTab === "/dashboard" ? "active-linkText" : "linkTextBtn"
                }
              >
                Dashboard
              </span>
            </div>
            <div className="linkBtn" onClick={() => navigate("/batchProcess")}>
              <span
                className={
                  activeTab === "/batchProcess"
                    ? "active-linkText"
                    : "linkTextBtn"
                }
              >
                Batch Process
              </span>
            </div>

            <div className="linkBtn" onClick={() => navigate("/")}>
              <span
                className={
                  activeTab === "/" ? "active-linkText" : "linkTextBtn"
                }
              >
                Adaptation Tool
              </span>
            </div>
            {/* <div
                className="linkBtn"
                onClick={() => navigate("/presetManager")}
              >
                <span
                  className={
                    activeTab === "/presetManager"
                      ? "active-linkText"
                      : "linkTextBtn"
                  }
                >
                  Preset Manager
                </span>
              </div> */}
            {/* <div className="linkBtn" onClick={() => navigate("/gallery")}>
                <span
                  className={
                    activeTab === "/gallery" ? "active-linkText" : "linkTextBtn"
                  }
                >
                  Gallery
                </span>
              </div> */}
          </div>
        </div>
      </div>

      <div>
        {/* <Button onClick={logout}>Logout</Button> */}
        <>
          <span
            style={{
              padding: "0 10px",
              color: "#323232",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {JSON.parse(localStorage.getItem("user")).name}
          </span>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomLeft"
            arrow
          >
            <Avatar size="large" icon={<UserOutlined />} />
          </Dropdown>
        </>
      </div>
    </header>
  );
};

export default Header;
