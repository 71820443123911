import img_1 from "../../assets/images/brand-1.svg";
import img_2 from "../../assets/images/brand-2.svg";
import img_3 from "../../assets/images/brand-3.svg";
import img_4 from "../../assets/images/brand-4.svg";
import img_5 from "../../assets/images/brand-5.svg";
import img_6 from "../../assets/images/brand-6.svg";
import img_7 from "../../assets/images/brand-7.svg";
import img_8 from "../../assets/images/brand-8.svg";
import img_9 from "../../assets/images/brand-9.svg";
import img_10 from "../../assets/images/brand-10.svg";
import img_11 from "../../assets/images/brand-11.svg";
import img_12 from "../../assets/images/brand-12.svg";
import img_13 from "../../assets/images/brand-13.svg";
import img_14 from "../../assets/images/brand-14.svg";
import img_15 from "../../assets/images/brand-15.svg";
import img_16 from "../../assets/images/brand-16.svg";
import img_17 from "../../assets/images/brand-17.svg";

export const data = [
  {
    key: 1,
    img: img_1,
    width: "118.12px",
    height: "26.85px",
  },
  {
    key: 2,
    img: img_2,
    width:"83px",
    height:"46px"
  },
  {
    key: 3,
    img: img_3,
    width:'104px',
    height:'37px'
  },
  {
    key: 4,
    img: img_4,
    width:'129px',
    height:'20px'
  },
  {
    key: 5,
    img: img_5,
    width:'125px',
    height:'37px'
  },
  {
    key: 6,
    img: img_6,
    width:'85px',
    height:'42px'
  },
  {
    key: 7,
    img: img_7,
    width:'132px',
    height:'18px'
  },
  {
    key: 8,
    img: img_8,
    width:'121px',
    height:'48px'
  },
  {
    key: 9,
    img: img_9,
    width:'139px',
    height:'21px'
  },
  {
    key: 10,
    img: img_10,
    width:'129.94px',
    height:'28.99px'
  },
  {
    key: 11,
    img: img_11,
    width:'123.94px',
    height:'19.33px'
  },
  {
    key: 12,
    img: img_12,
    width:'102.02px',
    height:'31.14px'
  },
  {
    key: 13,
    img: img_13,
    width:'110.61px',
    height:'41.88px'
  },
  {
    key: 14,
    img: img_14,
    width:'123.94px',
    height:'19.33px'
  },
  {
    key: 15,
    img: img_15,
    width:'133.16px',
    height:'28.99px'
  },
  {
    key: 16,
    img: img_16,
    width:'146.04px',
    height:'37.58px'
  },
  {
    key: 17,
    img: img_17,
    width:'117.05px',
    height:'44.03px'
    
  },
];
