import { createContext, useReducer, useEffect } from "react";
import useGuestLogin from "../hooks/useGuestLogin";
const initialState = {
  user: null,
  token: null,
  isAuth: false,
  isLoading: false,
  error: null,
};

const AuthContext = createContext(initialState);

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        user: action.payload,
        token: action.payload.token,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuth: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const {guestLoginProcess} = useGuestLogin();


  useEffect(() => {
    const userData = localStorage.getItem("user");
    const parsedUser = JSON.parse(userData);
    if (parsedUser && parsedUser.userType!="guest") {
      dispatch({ type: "LOGIN_SUCCESS", payload: parsedUser });
    }
    else{
      performGuestLogin();
    }
  }, []);

  const performGuestLogin = () => {
    guestLoginProcess()
    .then(guestUser=>{
      localStorage.setItem("user", JSON.stringify(guestUser));
    }
    );
  }


  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider};
