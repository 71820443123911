import { Button } from "antd";
import React from "react";
import "./style.scss";
import arrow from "../../assets/images/arrowtry.svg";
const TryNowBtn = (props) => {
  return (
    <div>
      <Button className="try-btn" onClick={props.onClick}>
        <span>
          Try Now
          <span className="wrapper">
            <img className="slide-1" alt="" src={arrow} />
            <img className="slide-2" alt="" src={arrow} />

            {/* <span >&#x2192;</span>
             */}
          </span>
        </span>
      </Button>
    </div>
  );
};

export default TryNowBtn;
