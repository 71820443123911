import { useEffect, useState, useRef, useContext, Fragment } from "react";
import { fabric } from "fabric";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Collapse,
  notification,
  Spin,
} from "antd";
import {
  CloseOutlined,
  RightOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  CompactPicker,
  SketchPicker,
  ChromePicker,
  BlockPicker,
} from "react-color";
import "react-color-palette/lib/css/styles.css";
import { useNavigate } from "react-router-dom";
import dropIcon from "../../assets/images/upload_icon.png";
import TransparentBackground from "../../assets/images/Transparent_for_bg.svg";

import useClip from "../../hooks/useClip";
import useMarketplace from "../../hooks/useMarketplace";
import usePreset from "../../hooks/usePreset";
import AllignmentBlock from "../allignmentBlock";
import UploadImage from "../../assets/images/upload.png";
import LoadingImage from "../../assets/images/loadingImg.png";
import PresetImage from "../../assets/images/preset.png";
import style from "./AppTool.module.css";
import PresetBlock from "../../CommonComponents/presetBlock";
import { AuthContext } from "../../context/authContext";
import { PresetContext } from "../../context/guestPresets";
import ClipImage from "../../CommonComponents/ClipImage";
import PresetModal from "../../CommonComponents/ModalContents/PresetModal";
import DynamicModal from "../../CommonComponents/DynamicModal/DynamicModal";
import SuccessModalContent from "../../CommonComponents/ModalContents/SuccessModalContent";
import RemoveBackgroundModal from "../../CommonComponents/ModalContents/RemoveBackgroundModal";
import HoldingModal from "../../CommonComponents/ModalContents/HoldingModal";
import debounce from "lodash.debounce";
import rgbHex from "rgb-hex";
import ResolutionLockedImage from "../../assets/images/lockResInGray.png";
import ResolutionUnlockedImage from "../../assets/images/unLockResInGray.png";
import { set } from "lodash";
import { async } from "q";
import useUpload from "../../hooks/useUpload";
import UseKeypoint from "../../hooks/useKeypoint";
import { upload } from "@testing-library/user-event/dist/upload";
import BackgroundImageBlock from "../backGroundImageBlock";
import useRemoveBackground from "../../hooks/useRemoveBackground";
import tryNowIcon from "../../assets/images/tryAdap.svg";
import useGetCredits from "../../hooks/getCredit";
import { FileDrop } from "react-file-drop";
// import "react-color-palette/";
import ColorPicker from "@mapbox/react-colorpickr";

function AppTool(props) {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [margin, setMargin] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });

  const [imageObj, setImageObj] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [croping, setCroping] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState({
    label: "Full Body",
    start: 0,
    end: -1,
    startFrom: "top",
    endTo: "bottom",
    index: -1,
  });
  const [isCliped, setIsCliped] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [color, setColor] = useState("transparent");
  const [backgroundImage, setBackgroundImage] = useState({
    file: null,
    fabricImage: null,
    url: null,
    bgType: "cover",
    tileCount: 1,
    isApplied: false,
  });
  const [backGroundImageType, setBackGroundImageType] = useState([
    "cover",
    "contain",
    "tile",
  ]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [marketPlace, setMarketPlace] = useState([]);
  const [preset, setPreset] = useState([]);
  const [isPresetLoading, setPresetLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const { GuestPresets, presetDispatch } = useContext(PresetContext);
  const { clipBackground, uploadImagePreSigned } = useClip();
  const { getMarketPlace } = useMarketplace();
  const { savePreset, getYourPreset, updateExistingPreset, deletePreset } =
    usePreset();
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [presetNameModal, setPresetNameModal] = useState(false);
  const [updatePresetModal, setUpdatePresetModal] = useState(false);
  const [presetFlag, setPresetFlag] = useState(false);
  const [presetSuccessFlag, setPresetSuccessFlag] = useState(false);
  const [isModalFlag, setIsModalFlag] = useState(false);
  const [resolutionLock, setResolutionLock] = useState(true);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [modalError, setModalError] = useState(false);
  const [deletePresetModal, setDeletePresetModal] = useState(false);
  const { uploadImageToS3Api } = useUpload();
  const { getKeypointApi } = UseKeypoint();
  const [loadingKeypoints, setLoadingKeypoints] = useState(false);
  const [availableCredits, setAvailableCredits] = useState("");

  const {
    removeBackgroundImageFromApi,
    error,
    successClip,
    updateCustomState,
  } = useRemoveBackground();
  const canvasWidth = useRef();
  const canvasHeight = useRef();
  const leftmargin = useRef();
  const topmargin = useRef();
  const rightmargin = useRef();
  const bottommargin = useRef();
  const { getUserAvailableCredits } = useGetCredits();

  useEffect(() => {
    getUserAvailableCredits().then((res) => {
      console.log(res, "getUserAvailableCredits");
      setAvailableCredits(res.data.credits);
    });
  }, []);

  useEffect(() => {
    getMarketPlace().then((res) => {
      console.log(res);
      setMarketPlace(res);
    });
    console.log("use-effect");
    var canvas = new fabric.Canvas("canvas", {
      preserveObjectStacking: true,
      backgroundColor: "#ffffff",
      selection: false,
      allowTouchScrolling: false,
    });
    //fabric.Object.prototype.transparentCorners = false;
    var canvasConatiner = document.getElementById("canvasContainer");
    canvas.setWidth(canvasConatiner.offsetWidth - 200);
    canvas.setHeight(canvasConatiner.offsetHeight - 200);
    canvas.renderAll();
    setDimension({
      width: canvasConatiner.offsetWidth - 20,
      height: canvasConatiner.offsetHeight - 20,
    });

    canvas.on("mouse:wheel", canvasMouseWheel);
    canvas.on("mouse:down", canvasMouseDown);
    canvas.on("mouse:move", canvasMouseMove);
    canvas.on("mouse:up", canvasMouseUp);

    document.getElementById("canvasContainer").canvas = canvas;

    canvas.renderAll();
  }, []);

  useEffect(() => {
    // debugger;
    if (state.isAuth) {
      fetchYourPreset();
    } else {
      setPreset(GuestPresets);
    }
  }, [state.isAuth, GuestPresets]);

  useEffect(() => {
    if (uploaded && !isPresetLoading) {
      updateDimensions();
    }
  }, [canvasImage]);

  useEffect(() => {
    if (backgroundImage.isApplied) {
      let file = backgroundImage.file;
      let bgImage = new Image();
      bgImage.src = URL.createObjectURL(file);
      bgImage.onload = () => {
        let fabricImage = new fabric.Image(bgImage);
        setBackgroundImage({
          ...backgroundImage,
          fabricImage: fabricImage,
        });
      };
    }
  }, [backgroundImage.file, backgroundImage.tileCount, backgroundImage.bgType]);

  useEffect(() => {
    if (!backgroundImage.isApplied && finalImage) {
      updateDimensions();
    }
  }, [backgroundImage.isApplied]);

  useEffect(() => {
    if (backgroundImage.isApplied) {
      updateDimensions();
    }
  }, [backgroundImage.fabricImage]);

  useEffect(() => {
    if (finalImage && !isPresetLoading) {
      updateDimensions();
    }
  }, [color]);

  const canvasMouseWheel = (opt) => {
    let canvas = document.getElementById("canvasContainer").canvas;
    var delta = opt.e.deltaY;
    var zoom = canvas.getZoom();
    console.log("canvasZoom: ", zoom);
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.1) zoom = 0.1;
    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  };

  const canvasMouseDown = (opt) => {
    let canvas = document.getElementById("canvasContainer").canvas;
    var evt = opt.e;
    if (evt.altKey === true) {
      canvas.isDragging = true;
      canvas.lastPosX = evt.clientX;
      canvas.lastPosY = evt.clientY;
    }
  };

  const canvasMouseMove = (opt) => {
    let canvas = document.getElementById("canvasContainer").canvas;
    if (canvas.isDragging) {
      var e = opt.e;
      var vpt = canvas.viewportTransform;
      vpt[4] += e.clientX - canvas.lastPosX;
      vpt[5] += e.clientY - canvas.lastPosY;
      canvas.requestRenderAll();
      canvas.lastPosX = e.clientX;
      canvas.lastPosY = e.clientY;
    }
  };

  const canvasMouseUp = (opt) => {
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.setViewportTransform(canvas.viewportTransform);
    canvas.isDragging = false;
  };

  const resetZoomAndPan = () => {
    // let canvas = document.getElementById("canvasContainer").canvas;
    // canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    // canvas.renderAll();
    updateDimensions();
  };

  const fetchYourPreset = () => {
    getYourPreset().then((res) => {
      //debugger;
      console.log(res);
      setPreset(res);
    });
  };
  const updateCanvas = () => {
    var canvas = document.getElementById("canvasContainer").canvas;
    // canvas.setWidth(200);
    // canvas.setHeight(200);
    canvas.setDimensions({ width: 200, height: 200 });
    canvas.requestRenderAll();
  };

  const addImageToCanvas = (img) => {
    // debugger;
    console.log(img, "imagesss");
    var canvas = document.getElementById("canvasContainer").canvas;
    canvas.clear();
    var imgInstance = new fabric.Image(img, {
      left: 0,
      top: 0,
      angle: 0,
      hasBorders: false,
      hasControls: false,
      selectable: false,
    });
    imgInstance.setCoords();
    setImageObj(imgInstance);
    setCanvasImage(imgInstance);
    setImageSize({ width: imgInstance.width, height: imgInstance.height });
    setAspectRatio(imgInstance.width / imgInstance.height);
    setUploaded(true);
    // setTimeout(() => {
    //   setIsModalFlag(true);
    // }, 2000);
    // canvasWidth.current.value = imgInstance.width;
    // canvasHeight.current.value = imgInstance.height;
  };

  const uploadImage = (e) => {
    console.log(e, "drop e");
    // debugger;
    const img = new Image();
    const canvas = document.getElementById("canvasContainer").canvas;
    canvas.clear();
    setImageFile(e.target.files[0]);
    img.src = URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      addImageToCanvas(img);
    };
    setIsModalFlag(true);
    console.log(img, "uploadImage");
  };

  const dropImage = (e) => {
    const img = new Image();
    const canvas = document.getElementById("canvasContainer").canvas;
    canvas.clear();
    setImageFile(e[0]);
    img.src = URL.createObjectURL(e[0]);
    img.onload = () => {
      addImageToCanvas(img);
    };
    setIsModalFlag(true);
    console.log(img, "uploadImage");
  };

  const updateDimensions = () => {
    //debugger;
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.renderAll();
    if (canvasImage) {
      setModalOpen(true);
      //var canvas = document.getElementById("canvasContainer").canvas;
      //canvas.clear();
      canvas.remove(...canvas.getObjects().concat());
      clearBackgroundImage();
      clearBackgroundColor();

      let pageWidth = parseInt(imageSize.width);
      let pageHeight = parseInt(imageSize.height);
      setAspectRatio(pageWidth / pageHeight);
      let availWidth = pageWidth - (margin.left + margin.right);
      let availHeight = pageHeight - (margin.top + margin.bottom);
      let centerX = margin.left + availWidth / 2;
      let centerY = margin.top + availHeight / 2;
      console.log(canvasImage);
      if (canvasImage.height / canvasImage.width > availHeight / availWidth) {
        canvasImage.scaleToHeight(availHeight);
        canvasImage.set({
          left: centerX - (canvasImage.width * canvasImage.scaleX) / 2,
          top: centerY - (canvasImage.height * canvasImage.scaleY) / 2,
        });
        canvasImage.setCoords();
      } else {
        canvasImage.scaleToWidth(availWidth);
        canvasImage.set({
          left: centerX - (canvasImage.width * canvasImage.scaleX) / 2,
          top: centerY - (canvasImage.height * canvasImage.scaleY) / 2,
        });
        canvasImage.setCoords();
      }

      if (backgroundImage.isApplied && backgroundImage.fabricImage) {
        //applyBackgroundPageCover(pageWidth, pageHeight,backgroundImage.fabricImage);
        applyBackgroundToPage(
          pageWidth,
          pageHeight,
          backgroundImage.bgType,
          backgroundImage.fabricImage,
          backgroundImage.tileCount
        );
      } else if (color != "transparent") {
        backgroundChange(color);
      }
      canvas.add(canvasImage);

      let tempImage = new Image();
      tempImage.src = canvas.toDataURL({
        format: "png",
        quality: 1,
        multiplier: 1,
        left: 0,
        top: 0,
        width: pageWidth,
        height: pageHeight,
      });
      tempImage.onload = () => {
        canvas.clear();
        //canvas.remove(...canvas.getObjects().concat());
        // if(color){
        //   canvas.backgroundColor = color;
        // }

        let image = new fabric.Image(tempImage);
        // image.left = rect.left;
        // image.top = rect.top;
        if (image.height / image.width > dimension.height / dimension.width) {
          image.scaleToHeight(dimension.height);
        } else {
          image.scaleToWidth(dimension.width);
        }
        image.set({
          selectable: false,
          hasControls: false,
        });
        image.setCoords();
        canvas.setWidth(image.width * image.scaleX);
        canvas.setHeight(image.height * image.scaleY);

        setFinalImage(image);
        canvas.add(image);
        if (backgroundImage.isApplied && backgroundImage.fabricImage) {
          clearBackgroundImage();
          clearBackgroundColor();
        }
        //canvas.centerObject(image);
        if (!backgroundImage.isApplied && color == "transparent") {
          setTransparentBackground();
        }

        // if (backgroundImage.isApplied) {
        //   applyBackground(
        //     backgroundImage.file,
        //     backgroundImage.bgType,
        //     backgroundImage.tileCount
        //   );
        // }

        canvas.requestRenderAll();
      };
      setModalOpen(false);
    }
  };

  const updateDimensionsFromPreset = (
    canvasImage,
    imageSize,
    margin,
    background,
    cropStart,
    cropEnd
  ) => {
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.renderAll();
    canvas.clear();
    if (canvasImage) {
      //setModalOpen(true);
      let imgInstance = canvasImage;

      imgInstance.scaleToHeight(imgInstance.height);
      imgInstance.setCoords();
      let cropedImage = new Image();
      cropedImage.src = imgInstance.toDataURL({
        left: 0,
        top: cropStart,
        width: imgInstance.width,
        height: cropEnd - cropStart,
      });
      cropedImage.onload = () => {
        //canvas.clear();

        let cropedImg = new fabric.Image(cropedImage);
        cropedImg.setCoords();
        canvasImage = cropedImg;
        var canvas = document.getElementById("canvasContainer").canvas;
        setCanvasImage(canvasImage);
        // canvas.add(canvasImage);
        // canvas.renderAll();
        let pageWidth = parseInt(imageSize.width);
        let pageHeight = parseInt(imageSize.height);
        setAspectRatio(pageWidth / pageHeight);
        let availWidth =
          pageWidth - (parseInt(margin.left) + parseInt(margin.right));
        let availHeight =
          pageHeight - (parseInt(margin.top) + parseInt(margin.bottom));
        let centerX = parseInt(margin.left) + availWidth / 2;
        let centerY = parseInt(margin.top) + availHeight / 2;
        console.log(canvasImage);
        if (canvasImage.height / canvasImage.width > availHeight / availWidth) {
          canvasImage.scaleToHeight(availHeight);
          canvasImage.set({
            left: centerX - (canvasImage.width * canvasImage.scaleX) / 2,
            top: centerY - (canvasImage.height * canvasImage.scaleY) / 2,
          });
          canvasImage.setCoords();
        } else {
          canvasImage.scaleToWidth(availWidth);
          canvasImage.set({
            left: centerX - (canvasImage.width * canvasImage.scaleX) / 2,
            top: centerY - (canvasImage.height * canvasImage.scaleY) / 2,
          });
          canvasImage.setCoords();
        }
        setColor(background);
        canvas.set({
          backgroundColor: background,
        });
        canvas.add(canvasImage);
        let tempImage = new Image();
        tempImage.src = canvas.toDataURL({
          format: "png",
          quality: 1,
          multiplier: 1,
          left: 0,
          top: 0,
          width: pageWidth,
          height: pageHeight,
        });

        tempImage.onload = () => {
          //canvas.clear();
          canvas.remove(...canvas.getObjects().concat());
          // if(color){
          //   canvas.backgroundColor = color;
          // }

          let image = new fabric.Image(tempImage);
          // image.left = rect.left;
          // image.top = rect.top;
          if (image.height / image.width > dimension.height / dimension.width) {
            image.scaleToHeight(dimension.height);
          } else {
            image.scaleToWidth(dimension.width);
          }
          image.set({
            selectable: false,
            hasControls: false,
          });
          image.setCoords();
          setFinalImage(image);
          canvas.setWidth(image.width * image.scaleX);
          canvas.setHeight(image.height * image.scaleY);
          canvas.add(image);
          //canvas.centerObject(image);
          if (background == "transparent") {
            setTransparentBackground();
          }
          canvas.requestRenderAll();
          setPresetLoading(false);
        };

        //setCanvasImage(image);
        //setCanvasImage(image);
      };
    }
  };

  const cropImage = (cropStart, cropEnd) => {
    let imgInstance = imageObj;

    imgInstance.scaleToHeight(imgInstance.height);
    imgInstance.setCoords();
    let cropedImage = new Image();
    cropedImage.src = imgInstance.toDataURL({
      left: 0,
      top: cropStart,
      width: imgInstance.width,
      height: cropEnd - cropStart,
    });
    cropedImage.onload = () => {
      //canvas.clear();
      let image = new fabric.Image(cropedImage);
      image.setCoords();
      setCanvasImage(image);
    };
    setModalOpen(false);
  };

  const cropImage2 = (data) => {
    if (croping) {
      let obj = croping[data];
      setSelectedCrop({
        label: obj.label,
        start: obj.start,
        end: obj.end,
        index: data,
        startFrom: obj.startFrom,
        endTo: obj.endTo,
      });
      cropImage(
        obj.start >= 0 ? obj.start : 0,
        obj.end >= 0 ? obj.end : imageObj.height
      );
    }
  };

  const resetClipPath = () => {
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.clear();
    canvas.add(imageObj);
    canvas.centerObject(imageObj);
    canvas.renderAll();
  };

  const imageSizeWidthChange = (data) => {
    //debugger;
    let tempheight = resolutionLock
      ? parseInt(data.target.value) * (1 / aspectRatio)
      : imageSize.height;
    setImageSize({
      ...imageSize,
      width: data.target.value,
      height: tempheight,
    });
  };

  const imageSizeHeightChange = (data) => {
    let ratio = imageSize.width / imageSize.height;
    let tempwidth = resolutionLock
      ? parseInt(data.target.value) * aspectRatio
      : imageSize.width;
    setImageSize({
      ...imageSize,
      height: data.target.value,
      width: tempwidth,
    });
  };

  const changeCanvasSize = (data) => {
    updateDimensions();
  };

  const zoomOut = () => {
    let canvas = document.getElementById("canvasContainer").canvas;
    let imgInstance = canvas.getObjects()[0];
    console.log(canvas.getZoom());
    canvas.zoomToPoint(
      { x: canvas.width / 2, y: canvas.height / 2 },
      canvas.height / (imgInstance.height * imgInstance.scaleY)
    );
    canvas.renderAll();
  };

  const leftMarginHandler = () => {
    const leftMargin = parseInt(leftmargin.current.value);
    const rightMargin = parseInt(rightmargin.current.value);
    const topMargin = parseInt(topmargin.current.value);
    const bottomMargin = parseInt(bottommargin.current.value);
    setMargin({
      left: leftMargin,
      right: rightMargin,
      top: topMargin,
      bottom: bottomMargin,
    });
  };
  const updateMargin = (data) => {
    let obj = {};
    data.margin.forEach((element) => {
      obj[element.name] = parseInt(element.value);
    });

    setMargin(obj);
  };

  const applyMargin = () => {
    updateDimensions();
  };

  useEffect(() => {
    if (error) {
      setModalOpen(false);
      notification.error({
        message: "Error",
        description: "Please try again",
      });
    }
  }, [error]);

  const clipImage = () => {
    setIsModalFlag(false);
    if (imageFile) {
      setModalOpen(true);
      uploadImagePreSigned(imageFile).then((data) => {
        console.log(data);
        updateCropingPoints(data.keypoints);
        // let obg = [
        //   {
        //     label: "Full Body",
        //     start: 0,
        //     end: -1,
        //     startFrom: "top",
        //     endTo: "bottom",
        //   },
        //   {
        //     label: "Head To Knee",
        //     start: 0,
        //     end: data.keypoints["At Knees"],
        //     startFrom: "Above Nose",
        //     endTo: "At Knees",
        //   },
        //   {
        //     label: "Head To Thigh",
        //     start: 0,
        //     end: data.keypoints["Above Knees"],
        //     startFrom: "Above Nose",
        //     endTo: "Above Knees",
        //   },
        //   {
        //     label: "Upperlip To Full Length",
        //     start: data.keypoints["Below Nose"],
        //     end: -1,
        //     startFrom: "Below Nose",
        //     endTo: "bottom",
        //   },
        //   {
        //     label: "nosebridge To Knee",
        //     start: data.keypoints["Above Nose"],
        //     end: data.keypoints["At Knees"],
        //     startFrom: "Above Nose",
        //     endTo: "At Knees",
        //   },
        //   {
        //     label: "nosebridge To Thigh",
        //     start: data.keypoints["Above Nose"],
        //     end: data.keypoints["Above Knees"],
        //     startFrom: "Above Nose",
        //     endTo: "Above Knees",
        //   },
        // ];
        // setCroping(obg);
        setSelectedCrop({
          label: "Full Body",
          start: 0,
          end: -1,
          startFrom: "top",
          endTo: "bottom",
          index: 0,
        });
        setIsCliped(true);
        let image = new fabric.Image.fromURL(
          data.clip_png,
          function (img) {
            let canvas = document.getElementById("canvasContainer").canvas;
            canvas.clear();
            setImageObj(img);
            setCanvasImage(img);
          },
          {
            crossOrigin: "Anonymous",
          }
        );
      });
    }
  };

  console.log(availableCredits, "availableCredits");
  const removeBackgroundFromImage = () => {
    if (availableCredits === 0) {
      notification.error({
        message: "Error",
        description: "Required Credits not Available ",
      });
    } else if (successClip) {
      notification.info({
        message: "Your Image is already clipped",
        // description: "Re ",
      });
    } else {
      setIsModalFlag(false);
      if (imageFile) {
        setModalOpen(true);
        removeBackgroundImageFromApi(imageFile)
          .then((data) => {
            // debugger;
            console.log(data, "daaaaaaaaaaaaaaaaaaaa");
            if (data.keypoints) {
              updateCropingPoints(data.keypoints);
            }
            setSelectedCrop({
              label: "Full Body",
              start: 0,
              end: -1,
              startFrom: "top",
              endTo: "bottom",
              index: 0,
            });
            setIsCliped(true);
            let image = new fabric.Image.fromURL(
              data.result,
              function (img) {
                let canvas = document.getElementById("canvasContainer").canvas;
                canvas.clear();
                setImageObj(img);
                setCanvasImage(img);
              },
              {
                crossOrigin: "Anonymous",
              }
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const updateCropingPoints = (keypoints) => {
    let obg = [
      {
        label: "Full Body",
        start: 0,
        end: -1,
        startFrom: "top",
        endTo: "bottom",
      },
      {
        label: "Head To Knee",
        start: 0,
        end: keypoints["At Knees"],
        startFrom: "Above Nose",
        endTo: "At Knees",
      },
      {
        label: "Head To Thigh",
        start: 0,
        end: keypoints["Above Knees"],
        startFrom: "Above Nose",
        endTo: "Above Knees",
      },
      {
        label: "Upperlip To Full Length",
        start: keypoints["Below Nose"],
        end: -1,
        startFrom: "Below Nose",
        endTo: "bottom",
      },
      {
        label: "nosebridge To Knee",
        start: keypoints["Above Nose"],
        end: keypoints["At Knees"],
        startFrom: "Above Nose",
        endTo: "At Knees",
      },
      {
        label: "nosebridge To Thigh",
        start: keypoints["Above Nose"],
        end: keypoints["Above Knees"],
        startFrom: "Above Nose",
        endTo: "Above Knees",
      },
    ];
    setCroping(obg);
    return obg;
  };

  const clearBackgroundImage = () => {
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
  };

  const clearBackgroundColor = () => {
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.backgroundColor = "transparent";
    canvas.renderAll();
  };

  const debounceBackgroundColorChange = (colorCode) => {
    // alert("wejkfiw")
    //debugger;
    let hexColor;
    if (colorCode.a > 0) {
      hexColor = `#${rgbHex(
        colorCode.r,
        colorCode.g,
        colorCode.b,
        colorCode.a
      )}`;
    } else if (
      colorCode.r === 0 &&
      colorCode.g === 0 &&
      colorCode.b === 0 &&
      colorCode.a === 0
    ) {
      hexColor = "transparent";
    } else {
      hexColor = `#${rgbHex(colorCode.r, colorCode.g, colorCode.b)}`;
    }
    if (hexColor !== color) {
      setColor(hexColor)
      // debounce(setColor(hexColor), 1500);
    }
  };

  const backgroundChange = (colorCode) => {
    //debugger;
    if (colorCode !== color) {
      setColor(colorCode);
    }
    let canvas = document.getElementById("canvasContainer").canvas;
    if (colorCode != "transparent") {
      canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
    }

    canvas.backgroundColor = colorCode;

    // get call using useEffect()
    //updateDimensions();
  };

  const setTransparentBackground = () => {
    setColor("transparent");
    let canvas = document.getElementById("canvasContainer").canvas;
    console.log("image: ", TransparentBackground);
    let image = new fabric.Image(TransparentBackground);
    console.log("fabric image: ", image);
    // canvas.setBackgroundImage(
    //   TransparentBackground,
    //   canvas.renderAll.bind(canvas),
    //   {
    //     backgroundImageOpacity: 1,
    //     scaleX: canvas.getWidth() / 578,
    //     scaleY: canvas.getHeight() / 578,
    //   }
    // );
    canvas.setBackgroundColor(
      { source: TransparentBackground, repeat: "repeat" },
      canvas.renderAll.bind(canvas)
    );
    canvas.renderAll();
  };

  const downloadImage = () => {
    if (finalImage) {
      finalImage.scaleToHeight(finalImage.height);
      var link = document.getElementById("link");
      link.setAttribute("download", "adaptationResult.png");
      link.setAttribute(
        "href",
        finalImage
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
      setDownloadFlag(true);
    }
  };

  const resetTool = () => {
    setMargin({ left: 0, top: 0, right: 0, bottom: 0 });
    setCroping([]);
    setFinalImage(null);
    setImageObj(null);
    setCanvasImage(null);
    setColor("transparent");
    setModalOpen(false);
    setImageFile(null);
    setImageSize({ width: 0, height: 0 });
    setIsCliped(false);
    setSelectedCrop({
      label: "Full Body",
      start: 0,
      end: -1,
      startFrom: "top",
      endTo: "bottom",
      index: 0,
    });
    setSelectedPreset(null);
    setPresetLoading(false);
    setAspectRatio(null);
    const canvas = document.getElementById("canvasContainer").canvas;
    const canvasConatiner = document.getElementById("canvasContainer");
    canvas.clear();
    canvas.setWidth(canvasConatiner.offsetWidth - 200);
    canvas.setHeight(canvasConatiner.offsetHeight - 200);
    canvas.set({
      backgroundColor: "#ffffff",
    });
    setBackgroundImage({
      file: null,
      url: null,
      tileCount: 1,
      fabricImage: null,
      bgType: "cover",
      isApplied: false,
    });
    updateCustomState(false);
  };

  const createPreset = (formData) => {
    if (imageObj) {
      let presetData = {
        name: formData.presetName,
        resolution: imageSize,
        margin: margin,
        cropping: selectedCrop
          ? selectedCrop
          : {
              label: "Full Body",
              start: 0,
              end: -1,
              startFrom: "top",
              endTo: "bottom",
            },
        background: color,
        clippingReq: isCliped,
        type: "client",
      };
      // debugger;
      console.log("presetData: ", preset);
      setPresetFlag(false);

      const samePreset = preset.find(
        (pr) =>
          pr.name.trim().toLowerCase() === presetData.name.trim().toLowerCase()
      );
      if (samePreset) {
        alert("Preset name already exists", "error");
        setModalError("Preset name already exists");
        return;
      }
      if (state.isAuth) {
        savePreset(presetData).then((data) => {
          console.log(data);
          setPresetNameModal(false);
          fetchYourPreset();
          setPresetSuccessFlag(true);
          setPresetFlag(false);
          // alert("Preset Saved Successfully", "success");
        });
      } else {
        presetDispatch({ type: "ADD_PRESET", payload: presetData });
        setPresetNameModal(false);
        alert("Preset Saved Successfully", "success");
      }
    }
  };

  const updatePreset = () => {
    setUpdatePresetModal(false);
    if (imageObj) {
      let presetData = {
        name: selectedPreset.name,
        resolution: imageSize,
        margin: margin,
        cropping: selectedCrop
          ? selectedCrop
          : {
              label: "Full Body",
              start: 0,
              end: -1,
              startFrom: "top",
              endTo: "bottom",
            },
        background: color,
        clippingReq: isCliped,
        type: "client",
      };
      if (state.isAuth) {
        console.log(presetData, selectedPreset._id, "preset ddaat");
        updateExistingPreset(presetData, selectedPreset._id).then((data) => {
          console.log(data);
          fetchYourPreset();
          setUpdatePresetModal(false);
          alert("Preset Updated Successfully");
        });
      } else {
        console.log(presetData, selectedPreset._id, "preset ddaat");

        presetDispatch({ type: "UPDATE_PRESET", payload: presetData });
        setUpdatePresetModal(false);
        alert("Preset Updated Successfully");
      }
    }
  };

  const applyPreset = async (dataPreset, type) => {
    //debugger;
    if (!imageObj) {
      return;
    }
    setSelectedPreset(dataPreset);
    setPresetLoading(true);
    if (type === "preset") {
      setImageSize(dataPreset.resolution);
      if (dataPreset.margin) {
        setMargin({
          left: parseInt(dataPreset.margin.left),
          right: parseInt(dataPreset.margin.right),
          top: parseInt(dataPreset.margin.top),
          bottom: parseInt(dataPreset.margin.bottom),
        });
      } else {
        setMargin({ left: 0, right: 0, top: 0, bottom: 0 });
        dataPreset.margin = { left: 0, right: 0, top: 0, bottom: 0 };
      }

      setColor(dataPreset.background);
      if (dataPreset.clippingReq) {
        if (isCliped) {
          // alert("alee 1")

          uploadImagePreSigned(imageFile).then((data) => {
            console.log(data);
            // let obg = [
            //   {
            //     label: "Full Body",
            //     start: 0,
            //     end: -1,
            //     startFrom: "top",
            //     endTo: "bottom",
            //   },
            //   {
            //     label: "Head To Knee",
            //     start: data.keypoints["Above Nose"],
            //     end: data.keypoints["At Knees"],
            //     startFrom: "Above Nose",
            //     endTo: "At Knees",
            //   },
            //   {
            //     label: "Head To Thigh",
            //     start: data.keypoints["Above Nose"],
            //     end: data.keypoints["Above Knees"],
            //     startFrom: "Above Nose",
            //     endTo: "Above Knees",
            //   },
            //   {
            //     label: "Upperlip To Full Length",
            //     start: data.keypoints["Below Nose"],
            //     end: -1,
            //     startFrom: "Below Nose",
            //     endTo: "bottom",
            //   },
            //   {
            //     label: "nosebridge To Knee",
            //     start: data.keypoints["Above Nose"],
            //     end: data.keypoints["At Knees"],
            //     startFrom: "Above Nose",
            //     endTo: "At Knees",
            //   },
            //   {
            //     label: "nosebridge To Thigh",
            //     start: data.keypoints["Above Nose"],
            //     end: data.keypoints["Above Knees"],
            //     startFrom: "Above Nose",
            //     endTo: "Above Knees",
            //   },
            // ];
            // setCroping(obg);
            let obg = updateCropingPoints(data.keypoints);
            setIsCliped(true);
            let image = new fabric.Image.fromURL(
              data.clip_png,
              function (img) {
                setImageObj(img);
                let cropObject = {};
                if (dataPreset.cropping) {
                  let crop = obg.filter(
                    (item) => item.label === dataPreset.cropping.label
                  );
                  console.log(crop, "check value");

                  crop = crop[0];
                  let index = obg.indexOf(crop);
                  cropObject = {
                    label: crop.label,
                    start: crop.start >= 0 ? crop.start : 0,
                    end: crop.end >= 0 ? crop.end : img.height,
                    index: index,
                    startFrom: crop.startFrom,
                    endTo: crop.endTo,
                  };
                  setSelectedCrop({
                    label: crop.label,
                    start: crop.start >= 0 ? crop.start : 0,
                    end: crop.end >= 0 ? crop.end : img.height,
                    index: index,
                    startFrom: crop.startFrom,
                    endTo: crop.endTo,
                  });
                } else {
                  setSelectedCrop({
                    label: "Full Body",
                    start: 0,
                    end: img.height,
                    startFrom: "top",
                    endTo: "bottom",
                  });
                  cropObject = {
                    label: "Full Body",
                    start: 0,
                    end: img.height,
                    startFrom: "top",
                    endTo: "bottom",
                  };
                }
                // canvas.add(img);
                // canvas.centerObject(img);
                // canvas.renderAll();
                updateDimensionsFromPreset(
                  img,
                  dataPreset.resolution,
                  dataPreset.margin,
                  dataPreset.background,
                  cropObject.start,
                  cropObject.end
                );
                //setCanvasImage(img);
              },
              {
                crossOrigin: "Anonymous",
              }
            );
          });
        } else {
          // alert("alee 2")

          //debugger;
          let cropObject = {};
          if (dataPreset.cropping) {
            let crop = croping.filter(
              (item) => item.label === dataPreset.cropping.label
            );
            console.log(croping, "crrrrrr");
            crop = crop[0];
            let index = croping.indexOf(crop);
            console.log(crop, "check value");

            // cropObject = {
            //   // label: crop.label,
            //   label:'full body',
            //   start: crop.start >= 0 ? crop.start : 0,
            //   end: crop.end >= 0 ? crop.end : imageObj.height,
            //   index: index,
            //   startFrom: crop.startFrom,
            //   endTo: crop.endTo,
            // };
            // setSelectedCrop({
            //   label: crop.label,
            //   start: crop.start >= 0 ? crop.start : 0,
            //   end: crop.end >= 0 ? crop.end : imageObj.height,
            //   index: index,
            //   startFrom: crop.startFrom,
            //   endTo: crop.endTo,
            // });
          } else {
            setSelectedCrop({
              label: "Full Body",
              start: 0,
              end: imageObj.height,
              startFrom: "top",
              endTo: "bottom",
            });
            cropObject = {
              label: "Full Body",
              start: 0,
              end: imageObj.height,
              startFrom: "top",
              endTo: "bottom",
              index: 0,
            };
          }
          updateDimensionsFromPreset(
            imageObj,
            dataPreset.resolution,
            dataPreset.margin,
            dataPreset.background,
            cropObject.start,
            cropObject.end
          );
        }
      } else {
        // alert("alee 3")
        let cropObject = {
          label: "Full Body",
          start: 0,
          end: imageObj.height,
          startFrom: "top",
          endTo: "bottom",
          index: 0,
        };
        setSelectedCrop({
          label: "Full Body",
          start: 0,
          end: imageObj.height,
          startFrom: "top",
          endTo: "bottom",
          index: 0,
        });
        let backgroundColor = null;
        if (dataPreset.background) {
          setColor(dataPreset.background);
          backgroundColor = dataPreset.background;
        } else {
          setColor("#ffffff");
          backgroundColor = "#ffffff";
        }
        updateDimensionsFromPreset(
          imageObj,
          dataPreset.resolution,
          dataPreset.margin,
          dataPreset.background,
          cropObject.start,
          cropObject.end
        );
      }
    }
    //setPresetLoading(false);
  };

  const saveButtonHandler = (e) => {
    console.log(selectedPreset, "selectedPreset");
    if (selectedPreset) {
      setUpdatePresetModal(true);
    } else {
      setPresetNameModal(true);
      setPresetFlag(true);
    }
  };

  const navigateToBatch = () => {
    navigate("/batchProcess");
  };

  const collapseIcon = (isActive) => {
    return isActive.isActive ? (
      <DownOutlined style={{ color: "#5F5F5F" }} />
    ) : (
      <RightOutlined style={{ color: "#5F5F5F" }} />
    );
  };

  const presetSubmit = () => {
    setPresetSuccessFlag(true);
    setPresetFlag(false);
  };
  const removeBackground = () => {
    // alert("Removing background");
    removeBackgroundFromImage();
  };

  const changeBackgroundImage = (e) => {
    // debugger;
    let file = e.target.files[0];
    setBackgroundImage({
      ...backgroundImage,
      file: file,
      isApplied: true,
    });
    // let bgImage = new Image();
    // bgImage.src = URL.createObjectURL(file);
    // bgImage.onload = () => {
    //   let fabricImage = new fabric.Image(bgImage);

    // };
    //applyBackground(file, backgroundImage.bgType, backgroundImage.tileCount);
  };

  const changeBackgroundImageType = (type) => {
    //debugger;
    setBackgroundImage({ ...backgroundImage, bgType: type });
    //applyBackground(backgroundImage.file, type, backgroundImage.tileCount);
  };

  const changeBackgroundImageTileCountDebounce = (e) => {
    let count = parseInt(e.target.value);
    debounce(
      setBackgroundImage({
        ...backgroundImage,
        tileCount: count,
      }),
      100
    );
  };

  const resetBackgroundImage = () => {
    setBackgroundImage({
      ...backgroundImage,
      file: null,
      isApplied: false,
      fabricImage: null,
    });
  };

  const changeBackgroundImageTileCount = (e) => {
    //debugger;
    let count = parseInt(e.target.value);
    setBackgroundImage({ ...backgroundImage, tileCount: count });
    applyBackground(backgroundImage.file, backgroundImage.bgType, count);
  };

  const applyBackground = (file, type, tileCount = 1) => {
    //debugger;
    let bgImage = new Image();
    bgImage.src = URL.createObjectURL(file);
    bgImage.onload = () => {
      let canvas = document.getElementById("canvasContainer").canvas;
      //debugger;
      let fabricImage = new fabric.Image(bgImage);
      setBackgroundImage({
        ...backgroundImage,
        file: file,
        isApplied: true,
        fabricImage: fabricImage,
      });
      //applyBackgroundConatain(canvas, fabricImage);
      // applyBackgroundCover(canvas, fabricImage);
      clearBackgroundImage();
      clearBackgroundColor();
      if (type === "contain") {
        applyBackgroundConatain(canvas, fabricImage);
      } else if (type === "cover") {
        applyBackgroundCover(canvas, fabricImage);
      } else {
        applyBackgroundTile(canvas, fabricImage, tileCount);
      }
    };
  };

  const applyBackgroundToPage = (
    pageWidth,
    pageHeight,
    type,
    fabricImage,
    tileCount
  ) => {
    // clearBackgroundImage();
    // clearBackgroundColor();
    if (type === "contain") {
      applyBackgroundPageContain(pageWidth, pageHeight, fabricImage);
    } else if (type === "cover") {
      applyBackgroundPageCover(pageWidth, pageHeight, fabricImage);
    } else {
      applyBackgroundPageTile(pageWidth, pageHeight, fabricImage, tileCount);
    }
  };

  const applyBackgroundConatain = (canvas, fabricImage) => {
    if (canvas.width / canvas.height > fabricImage.width / fabricImage.height) {
      fabricImage.scaleToHeight(canvas.height);
    } else {
      fabricImage.scaleToWidth(canvas.width);
    }
    //debugger;
    canvas.setBackgroundImage(fabricImage, canvas.renderAll.bind(canvas), {
      left: canvas.width / 2 - (fabricImage.width * fabricImage.scaleX) / 2,
      top: canvas.height / 2 - (fabricImage.height * fabricImage.scaleY) / 2,
    });
  };

  const applyBackgroundPageContain = (pageWidth, pageHeight, fabricImage) => {
    if (pageWidth / pageHeight > fabricImage.width / fabricImage.height) {
      fabricImage.scaleToHeight(pageHeight);
    } else {
      fabricImage.scaleToWidth(pageWidth);
    }
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.setBackgroundImage(fabricImage, canvas.renderAll.bind(canvas), {
      left: pageWidth / 2 - (fabricImage.width * fabricImage.scaleX) / 2,
      top: pageHeight / 2 - (fabricImage.height * fabricImage.scaleY) / 2,
    });
  };

  const applyBackgroundPageCover = (pageWidth, pageHeight, fabricImage) => {
    let scaleFactor = 1;
    //debugger;
    if (pageWidth / pageHeight > fabricImage.width / fabricImage.height) {
      fabricImage.scaleToWidth(pageWidth);
      scaleFactor = fabricImage.width / pageWidth;
    } else {
      fabricImage.scaleToHeight(pageHeight);
      scaleFactor = fabricImage.height / pageHeight;
    }
    let leftPoint = (fabricImage.width * fabricImage.scaleX - pageWidth) / 2;
    let topPoint = (fabricImage.height * fabricImage.scaleY - pageHeight) / 2;
    let rect = new fabric.Rect({
      width: pageWidth * scaleFactor,
      height: pageHeight * scaleFactor,
      left: -(pageWidth * scaleFactor) / 2,
      top: -(pageHeight * scaleFactor) / 2,
      fill: "black",
    });
    fabricImage.clipPath = rect;
    let canvas = document.getElementById("canvasContainer").canvas;
    canvas.setBackgroundImage(fabricImage, canvas.renderAll.bind(canvas), {
      left: pageWidth / 2 - (fabricImage.width * fabricImage.scaleX) / 2,
      top: pageHeight / 2 - (fabricImage.height * fabricImage.scaleY) / 2,
    });
  };

  const applyBackgroundCover = (canvas, fabricImage) => {
    let scaleFactor = 1;
    if (canvas.width / canvas.height > fabricImage.width / fabricImage.height) {
      fabricImage.scaleToWidth(canvas.width);
      scaleFactor = fabricImage.width / canvas.width;
    } else {
      fabricImage.scaleToHeight(canvas.height);
      scaleFactor = fabricImage.height / canvas.height;
    }
    let leftPoint = (fabricImage.width / 2 - canvas.width * scaleFactor) / 2;
    let topPoint = (fabricImage.height - canvas.height * scaleFactor) / 2;
    let rect = new fabric.Rect({
      width: canvas.width * scaleFactor,
      height: canvas.height * scaleFactor,
      left: -((canvas.width * scaleFactor) / 2),
      top: -((canvas.height * scaleFactor) / 2),
      fill: "black",
    });
    rect.setCoords();
    //debugger;
    fabricImage.clipPath = rect;
    fabricImage.setCoords();
    //debugger;
    canvas.setBackgroundImage(fabricImage, canvas.renderAll.bind(canvas), {
      left: canvas.width / 2 - (fabricImage.width * fabricImage.scaleX) / 2,
      top: canvas.height / 2 - (fabricImage.height * fabricImage.scaleY) / 2,
    });
  };

  const applyBackgroundTile = (canvas, fabricImage, tileCount) => {
    let canvasElement = fabricImage.toCanvasElement({
      multiplier: canvas.width / tileCount / fabricImage.width,
      left: 0,
      top: 0,
    });
    // canvasElement.setCoords();
    canvas.setBackgroundColor(
      { source: canvasElement, repeat: "repeat" },
      canvas.renderAll.bind(canvas)
    );
    canvas.renderAll();

    // canvas.backgroundColor = new fabric.Pattern({source: fabricImage});
    // canvas.renderAll();
  };

  const applyBackgroundPageTile = (
    pageWidth,
    pageHeight,
    fabricImage,
    tileCount
  ) => {
    let scaleFactor = pageWidth / tileCount;
    //scaleFactor = scaleFactor/fabricImage.width;
    fabricImage.scaleToWidth(scaleFactor);
    let canvasElement = fabricImage.toCanvasElement({
      multiplier: 1,
      left: 0,
      top: 0,
    });

    let canvas = document.getElementById("canvasContainer").canvas;

    canvas.setBackgroundColor(
      { source: canvasElement, repeat: "repeat" },
      canvas.renderAll.bind(canvas)
    );
    //canvas.renderAll();
    // canvas.backgroundColor = new fabric.Pattern({source: fabricImage});
    // canvas.renderAll();
  };
  const processDeletePreset = (item) => {
    // debugger;
    console.log(item);
    setDeletePresetModal(item._id);
  };

  const deleteYourPreset = async (item) => {
    console.log(item);
    deletePreset(item)
      .then((res) => {
        console.log(res);
        setDeletePresetModal(null);
        fetchYourPreset();
        notification.success({
          message: "Success",
          description: "Preset deleted successfully",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  const updateKeypointsForInputImage = () => {
    // debugger;
    setLoadingKeypoints(true);
    const keydata = uploadImageHandler(imageFile);
    keydata
      .then((res) => {
        console.log(res);
        // debugger;
        return getKeypointApi(res);
      })
      .then((res) => {
        console.log(res);
        updateCropingPoints(res);
        setLoadingKeypoints(false);
        // debugger;
      })
      .catch((err) => {
        console.log(err);
        setLoadingKeypoints(false);
      });
  };

  const uploadImageHandler = async (image) => {
    // debugger;
    return uploadImageToS3Api(image);
  };
  console.log("setSuccessClip", successClip);
  return (
    <div
      style={{
        // height: props.authLogin && "92vh",
        marginTop: props.authLogin && "7rem",
        backgroundColor: props.authLogin && "#FFF6F1",
        padding: props.authLogin && "4rem",
      }}
    >
      {props.authLogin && (
        <div className="try_now_cont">
          <img alt="" src={tryNowIcon} />
          <span
            className="inter"
            style={{
              color: "#544E47",
              marginBottom: "3rem",
            }}
          >
            Make your products live in a day at a flat low price per product!
          </span>
        </div>
      )}
      <div className={style.root}>
        <div className={style.presetBlock}>
          <Space
            direction="vertical"
            style={{ marginTop: "10px", width: "100%" }}
          >
            {preset && preset.length > 0 && (
              <Collapse
                expandIcon={collapseIcon}
                size="small"
                expandIconPosition="end"
              >
                <Collapse.Panel
                  className={`${style.panelClass}`}
                  header="Your Presets"
                  key="y1"
                >
                  <div className={style.presets}>
                    {preset.map((item, index) => {
                      return (
                        <PresetBlock
                          key={"preset_" + index}
                          image={PresetImage}
                          title={item.name}
                          clickEvent={() => applyPreset(item, "preset")}
                          deleteEvent={() => processDeletePreset(item)}
                          closeOption={true}
                        />
                      );
                    })}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
            {marketPlace && (
              <Collapse
                expandIcon={collapseIcon}
                size="small"
                expandIconPosition="end"
                // collapsible={!imageObj ? "disabled" : ""}
                defaultActiveKey={["m1"]}
                className={style.collapsePanelClass}
              >
                <Collapse.Panel
                  className={style.panelClass}
                  header="Market Place Presets"
                  key="m1"
                >
                  <div className={style.presets}>
                    {marketPlace.map((item, index) => {
                      return (
                        <PresetBlock
                          key={item._id}
                          image={item.imageUrl}
                          title={item.name}
                          clickEvent={() => applyPreset(item, "preset")}
                          closeOption={false}
                        />
                      );
                    })}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </Space>
        </div>
        <div
          className={style.firstBlock}
          style={
            {
              // background:'pink !important',
            }
          }
        >
          {imageObj && (
            <Button
              size="small"
              onClick={resetTool}
              className={`${style.cancelButton} ${style.orangeBlankButton}`}
              icon={<CloseOutlined />}
            ></Button>
          )}
          <div id="canvasContainer" className={style.App}>
            <FileDrop className="full_width" onDrop={dropImage}>
              {/* onDrop={handleDrop}  */}
              <canvas
                id="canvas"
                className={style.canvasClass}
                style={{
                  // position:'relative !important',
                  overflow: "auto",
                  background: "pink !important",
                }}
              ></canvas>
              {!imageObj && (
                <div className={style.browseBlock}>
                  <div
                    className="drag_dropCard"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div className="drag_content">
                      <div>
                        <img alt="" src={dropIcon} />
                      </div>
                      <div></div>
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => uploadImage(e)}
                        />
                        <p
                          style={{
                            margin: "10px",
                            color: "#DE6B32",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Drag and Drop your files <u>Here</u>
                          </span>
                        </p>

                        <span className="text_span">Or</span>
                        <p
                          style={{
                            margin: "10px",
                            color: "#DE6B32",
                            cursor: "pointer",
                            border: "1px solid #DE6B32",
                            width: "172px",
                            borderRadius: "10px",
                            padding: "10px 20px",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Browse an image
                          </span>
                        </p>
                      </label>
                      <span className="text_span">
                        jpg, jpeg, png & tiff files supported
                      </span>
                      <div>
                        <span className="text_span">
                          File size not more than 15MB
                        </span>
                      </div>
                    </div>
                    {props.authLogin && (
                      <div className="text_span batch_sign">
                        <span className="color-orange">Sign in &nbsp;</span> for
                        Batch Process
                      </div>
                    )}
                  </div>
                </div>
              )}
            </FileDrop>
          </div>
        </div>
        <div className={style.secondBlock}>
          {selectedPreset && (
            <Typography.Paragraph className={style.presetText}>
              <span className={style.grayColor}>Preset:</span>{" "}
              {selectedPreset.name}
            </Typography.Paragraph>
          )}
          <div style={{ overflow: "auto", height: "90%", width: "100%" }}>
            <Space
              direction="vertical"
              style={{ marginTop: "10px", width: "100%" }}
            >
              <Collapse
                expandIcon={collapseIcon}
                size="small"
                expandIconPosition="end"
              >
                <Collapse.Panel
                  header="Clip Image"
                  className={style.panelClass}
                  key="1"
                >
                  <ClipImage
                    clipEvent={removeBackgroundFromImage}
                    clipEventShadow={removeBackground}
                  />
                </Collapse.Panel>
              </Collapse>
              <Collapse
                size="small"
                expandIcon={collapseIcon}
                expandIconPosition="end"
              >
                <Collapse.Panel
                  header="Resolution"
                  className={style.panelClass}
                  key="1"
                >
                  <div>
                    <Form
                      className={style.formClass}
                      name="dimensions"
                      layout="inline"
                    >
                      <Form.Item
                        name="width"
                        className={style.removeMarginInlineEnd}
                      >
                        <span>W:</span>
                        <Input
                          size="small"
                          style={{ width: "70px" }}
                          type="number"
                          onChange={imageSizeWidthChange}
                          value={imageSize.width}
                          ref={canvasWidth}
                        ></Input>
                      </Form.Item>
                      <div>
                        <img
                          className={style.resolutionLockImageClass}
                          src={
                            resolutionLock
                              ? ResolutionLockedImage
                              : ResolutionUnlockedImage
                          }
                          alt="resolutionLock"
                          onClick={() => setResolutionLock(!resolutionLock)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <Form.Item
                        name="height"
                        className={style.removeMarginInlineEnd}
                      >
                        <span>H:</span>
                        <Input
                          type="number"
                          size="small"
                          style={{ width: "70px" }}
                          onChange={imageSizeHeightChange}
                          value={imageSize.height}
                          ref={canvasHeight}
                        ></Input>
                      </Form.Item>
                      <Form.Item className={style.removeMarginInlineEnd}>
                        <Button
                          type="default"
                          size="small"
                          className={style.orangeBlankButton}
                          onClick={changeCanvasSize}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Collapse.Panel>
              </Collapse>
              <Collapse
                expandIcon={collapseIcon}
                size="small"
                expandIconPosition="end"
              >
                <Collapse.Panel
                  header="Margin"
                  className={style.panelClass}
                  key="1"
                >
                  <AllignmentBlock
                    data={{
                      margin: [
                        { name: "left", value: margin.left },
                        { name: "right", value: margin.right },
                        { name: "top", value: margin.top },
                        { name: "bottom", value: margin.bottom },
                      ],
                    }}
                    updateMethod={updateMargin}
                  ></AllignmentBlock>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ marginTop: "10px" }}
                      type="default"
                      size="small"
                      onClick={applyMargin}
                      className={style.orangeBlankButton}
                    >
                      Apply Margin
                    </Button>
                  </div>
                </Collapse.Panel>
              </Collapse>
              <Collapse
                expandIcon={collapseIcon}
                size="small"
                expandIconPosition="end"
              >
                <Collapse.Panel
                  header="Croping"
                  className={style.panelClass}
                  key="1"
                >
                  <div className={style.blockBody}>
                    {loadingKeypoints ? (
                      <Fragment>
                        <center>
                          {" "}
                          <Spin
                            tip="Loading Croping Points"
                            style={{ color: "#DE6B32" }}
                            indicator={
                              <LoadingOutlined twoToneColor={"#DE6B32"} />
                            }
                          />
                        </center>
                      </Fragment>
                    ) : (
                      <Select
                        wrap
                        style={{ width: "100%" }}
                        defaultValue={
                          selectedCrop.index > -1
                            ? selectedCrop.index
                            : "Select Croping"
                        }
                        onChange={(e) => cropImage2(e)}
                        size="small"
                        options={croping.map((crop, index) => {
                          return { value: index, label: crop.label };
                        })}
                      ></Select>
                    )}
                  </div>
                </Collapse.Panel>
              </Collapse>
              <Collapse
                expandIcon={collapseIcon}
                size="small"
                expandIconPosition="end"
              >
                <Collapse.Panel
                  header="Background Color"
                  className={style.panelClass}
                  key="1"
                >
                  <div id="backgroundColorChange" className={style.centerAlign}>
                    <SketchPicker
                      color={color}
                      presetColors={[
                        "TRANSPARENT",
                        "#000000",
                        "#ffffff",
                        "#F85479",
                        "#F8B400",
                        "#F8F800",
                        "#00F8F8",
                      ]}
                      disableAlpha={false}
                      onChange={(clr) => debounceBackgroundColorChange(clr.rgb)}
                    />
                    {/* <ColorPicker onChange={console.log} /> */}
                  </div>
                </Collapse.Panel>
              </Collapse>
              <Collapse size="small" expandIconPosition="end">
                <Collapse.Panel
                  header="Background Image"
                  className={style.panelClass}
                  key="1"
                >
                  <div className={style.centerAlign}>
                    <BackgroundImageBlock
                      backgroundImage={backgroundImage.file}
                      backgroundImageUrl={backgroundImage.url}
                      changeBackgroundImage={changeBackgroundImage}
                      backGroundImageTypeChange={changeBackgroundImageType}
                      tileCountChange={changeBackgroundImageTileCountDebounce}
                      resetBackgroundImage={resetBackgroundImage}
                    ></BackgroundImageBlock>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Space>
          </div>
          <div className={style.buttonBlock}>
            <Button
              type="primary"
              size="small"
              className={`${style.buttonClass} ${style.orangeBackground}`}
              onClick={saveButtonHandler} //{createPreset}
              disabled={
                !imageObj ||
                (selectedPreset && selectedPreset.type != "client"
                  ? true
                  : false)
              }
            >
              Save Preset
            </Button>

            <Button
              className={`${style.buttonClass} ${style.grayButon}`}
              type="default"
              size="small"
              onClick={downloadImage}
            >
              Download
            </Button>
            <Button
              className={`${style.buttonClass} ${style.orangeBlankButton}`}
              type="default"
              size="small"
              onClick={resetZoomAndPan}
            >
              Reset Zoom & Pan
            </Button>

            {/* <Button
        className={style.buttonClass}
        type="primary"
        size="small"
        onClick={navigateToBatch}
      >
        Batch Process
      </Button>{" "} */}
          </div>
          <a id="link"></a>
        </div>
        {isModalOpen && (
          <DynamicModal
            open={isModalOpen}
            width={500}
            close={() => {
              setModalOpen(false);
            }}
            closable={true}
            footer={null}
            customClassName="modal2"
            component={<HoldingModal text={"We are Clipping your Image"} />}
          />
        )}
        {isPresetLoading && (
          <DynamicModal
            open={isPresetLoading}
            width={500}
            close={() => {
              setPresetLoading(false);
            }}
            closable={true}
            footer={null}
            customClassName="modal2"
            component={<HoldingModal text={"We are Loading your Presets"} />}
          />
        )}
        {/* <Modal closable={false} footer={null} open={isModalOpen}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={LoadingImage} width="150" height="130" />
        <h4>Hold on! We are Clipping your Image</h4>
      </div>
    </Modal>
    <Modal closable={false} footer={null} open={isPresetLoading}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={LoadingImage} width="150" height="130" />
        <h4>Hold on! We are Applying preset to your Image</h4>
      </div>
    </Modal> */}
        {presetFlag && (
          <DynamicModal
            open={presetFlag}
            width={500}
            close={() => {
              setPresetFlag(false);
            }}
            closable={true}
            footer={null}
            customClassName="modal2"
            component={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Form
                  // form={Form.useForm()[0]}
                  layout="vertical"
                  onFinish={createPreset}
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Form.Item
                    label="Preset Name"
                    name="presetName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your preset name!",
                      },
                    ]}
                    className="label"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        background: "#DE6B32",
                        color: "#ffffff",
                        width: "180px",
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              // <PresetModal name={"Preset Name"} presetSubmit={presetSubmit} />
            }
          />
        )}
        {presetSuccessFlag && (
          <DynamicModal
            open={presetSuccessFlag}
            width={500}
            close={() => {
              setPresetSuccessFlag(false);
            }}
            closable={true}
            footer={null}
            customClassName="modal2"
            component={
              <SuccessModalContent
                successMsg={"Your Preset Saved Successfully"}
                hr={true}
                batchProcessBtn={true}
                dashboard={false}
                close={true}
                closeHandeler={() => {
                  setPresetSuccessFlag(false);
                }}
              />
            }
          />
        )}
        {deletePresetModal && (
          <DynamicModal
            open={deletePresetModal}
            width={500}
            close={() => {
              setDeletePresetModal(false);
            }}
            closable={true}
            footer={null}
            customClassName="modal2"
            component={
              <SuccessModalContent
                successMsg={"Are you sure you want to delete this preset?"}
                hr={true}
                close={true}
                confirm={true}
                confirmHandler={() => deleteYourPreset(deletePresetModal)}
                closeHandeler={() => {
                  setDeletePresetModal(false);
                }}
              />
            }
          ></DynamicModal>
        )}
        {isModalFlag && (
          <DynamicModal
            open={isModalFlag}
            width={650}
            close={() => {
              setIsModalFlag(false);
              updateCustomState(false);
              // setSuccessClip(false);
            }}
            footer={null}
            closable={true}
            customClassName="modal1"
            component={
              <RemoveBackgroundModal
                removeBackground={removeBackground}
                keepOriginal={() => {
                  updateKeypointsForInputImage();
                  setIsModalFlag(false);
                }}
                imageModalUrl={canvasImage?.toDataURL() ?? ""}
              />
            }
          />
        )}
        {downloadFlag && (
          <DynamicModal
            open={downloadFlag}
            width={500}
            close={() => {
              setDownloadFlag(false);
            }}
            closable={true}
            footer={null}
            customClassName="modal2"
            component={
              <SuccessModalContent
                successMsg={"Your Image Download Successfully"}
                hr={true}
                batchProcessBtn={false}
                dashboard={false}
                close={true}
                closeHandeler={() => {
                  setDownloadFlag(false);
                }}
              />
            }
          />
        )}
        {/* <Modal
      open={presetNameModal}
      onCancel={() => setPresetNameModal(false)}
      footer={null}
    >
      <Form
        form={Form.useForm()[0]}
        layout="vertical"
        onFinish={createPreset}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Preset Name"
          name="presetName"
          rules={[
            {
              required: true,
              message: "Please input your preset name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal> */}
        <Modal
          open={updatePresetModal}
          okText={"Yes"}
          cancelText={"No"}
          onCancel={() => {
            setUpdatePresetModal(false);
          }}
          footer={
            <div>
              <Button type="primary" onClick={(e) => updatePreset()}>
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  setUpdatePresetModal(false);
                  setPresetNameModal(true);
                }}
              >
                No
              </Button>{" "}
            </div>
          }
        >
          Do you want to update selected Preset?
        </Modal>
      </div>
    </div>
  );
}

export default AppTool;
