import React, { createContext, useState } from "react";
export const ToasterContext = createContext([]);

 const ToasterProvider = (props) => {
  const [toasterShow, setToasterShow] = useState(false);
  return (
    <ToasterContext.Provider value={{ toastFlag:[toasterShow, setToasterShow] }}>
      {props?.children}
    </ToasterContext.Provider>
  );
};
export default ToasterProvider;
