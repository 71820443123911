import React, { useRef } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Button, Form, Input, Divider } from "antd";
import "./style.scss";
import FR123 from "../../assets/images/FR123.jpg";
import metaModel from "../../assets/images/ImageResizer.svg";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { GoogleBtn } from "../../CommonComponents/googleBtn";

const onFinish = (values) => {
  console.log("Success:", values);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const Login = (props) => {
  const refGoogle = useRef();
  console.log(props, "login props1");
  return (
    <>
      <Row>
        <Col className="container_left" span={12}>
          <div className="header_top">
            <div className="text-center">
              <img src={metaModel} alt="" />
            </div>
            <div className="description">
              Tailored for garment images, our image resizer is specifically
              designed to cater to the needs of brands, retailers, studios, and
              long-tail clients
            </div>
          </div>
          <div className="w-100">
            <div>
              <GoogleBtn
                text="Connect With Google"
                clickHandler={() => props.successLogin()}
              />
              {/* <GoogleLogin
                onSuccess={(response) => {
                  props.successLogin(jwt_decode(response.credential));
                }}
                onError={(response) => {
                  console.log(response);
                  props.setIsModalVisible(false);
                }}
                // ref={refGoogle}
              /> */}
            </div>
          </div>
          <div className="form">
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="E-mail"
                name="email"
                className="email item"
                rules={[
                  {
                    required: true,
                    message: "The email field is required.",
                  },
                ]}
              >
                <Input className="space" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                className="password-item"
                rules={[
                  {
                    required: true,
                    message: "The password field is required.",
                  },
                ]}
              >
                <Input.Password className="space" />
              </Form.Item>
              {/* <div className="forgot-pass">
                <a className="login-form-forgot grey " href="">
                  Forgot password?
                </a>
              </div> */}

              <Form.Item className="sign-in item">
                <Button
                  className="submit"
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  Sign In
                </Button>
              </Form.Item>
              <div className="already-have">
                Don't have an account yet?{" "}
                <span
                  className="login-form-forgot create-account"
                  to="/sign-up"
                >
                  Create an account
                </span>
              </div>
            </Form>
          </div>
        </Col>
        <Col className="container_right" span={12}>
          <img src={FR123} alt="img" width={400} height={400} />

          {/* <img className="image" src={image} alt="" /> */}
        </Col>
      </Row>
    </>
  );
};

export default Login;
