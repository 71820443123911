import axios from "axios";

const clipImageApi = async (image) => {
    const formData = new FormData();
    formData.append("media", image);
    formData.append("Iscropping_flag", "true");
    const res = await axios.post(
        //"http://3.7.232.225:8000/m-clipper/roi-crop",
        "https://imageedit.flixstock.com/m-clipper/roi-crop",
        formData
    );
    return res;
    // then((response) => {
    //     
    //     console.log(response);
    // })
    // .catch((error) => {
    //     
    //     console.log(error);
    // }
    // ); 

}

const getPresignedUrl = async (imageName) => {
    const res = await axios.post(
        //"http://3.7.232.225:8000/cropper/get-pre-signed-url",
        "https://imageedit.flixstock.com/cropper/get-pre-signed-url",
        {
            "fileName": imageName
        }
    );
    return res;
}

const uploadImageToS3 = async (url, image) => {
    const res = axios.put(url, image)
    return res;
}

const cropImage = async (key) => {
    const res = await axios.post(
        //"http://3.7.232.225:8000/cropper/keypoints-clip",
        "https://imageedit.flixstock.com/cropper/keypoints-clip",
        {
            "imagePath":key
        });
    return res;
}


const useClip = () => {
    const clipBackground = (image) => {
        return clipImageApi(image);
    }

    const uploadImagePreSigned = async (image) => {
        
        let res = await getPresignedUrl(image.name);
        if (res.status == 200) {
             const presignedUrl = res.data.url;
            const res2 = await uploadImageToS3(presignedUrl, image);
            if (res2.status == 200) {
                
                const res3 = await cropImage(res.data.key);
                if (res3.status == 200) {
                    return res3.data;
                }
            }
        }

    }




    return {clipBackground,uploadImagePreSigned}
}
export default useClip;